import { Box, TableCell, TableRow } from "@mui/material";

interface NoRecordFoundComponentProps
  extends Readonly<{
    cellSpan: number;
  }> {}
const NoRecordFoundComponent: React.FC<NoRecordFoundComponentProps> = ({
  cellSpan,
}) => {
  return (
    <TableRow className="text-center m-5">
      <TableCell
        colSpan={cellSpan}
        padding="none"
        style={{ borderBottom: "none" }}
      >
        <Box
          bgcolor="#FFFFFF"
          color="#29334c"
          textAlign="center"
          border="0px solid #ccc"
          borderRadius={2}
          p={2}
          width="full"
          boxSizing="border-box"
          margin={0}
        >
          No record found
        </Box>
      </TableCell>
    </TableRow>
  );
};
export default NoRecordFoundComponent;
