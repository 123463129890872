
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
  cancelText
}: any) => {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={onClose}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>

        {message && <DialogContent dividers>{message}</DialogContent>}
        <DialogActions>
          <Button onClick={onConfirm} color="primary" variant="contained">
            {confirmText}
          </Button>
          <Button onClick={onClose} color="primary" variant="outlined">
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
