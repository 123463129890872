import { IconButton, Box } from "@mui/material";
import SearchForm from "../DashBoard/DashboardSearchForm";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import PaginationControl from "../Layout/Pagination";
import "../Layout/Pagination/index.css";
import FullPageLoader from "../Shared/FullPageLoader";
import useStore from "../../store/store";
import { RECORDS_PER_PAGE } from '../../constants/index';
import { debounce } from 'lodash';
import { AppointmentServiceInternal } from "../../services/appointment.service";
import ITPTable from "./ITPTable";


const recordsPerPage = RECORDS_PER_PAGE;

function ITPUserDashboard() {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);   

    const user: any = useStore();
    const paginatedData = data?.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (search : string) => {        
        setSearch(search);
    }; 
    const debouncedSearchChange = debounce(handleSearchChange, 500);

    const handleSearchChangeDebounced = (search: string) => {
        debouncedSearchChange(search);
    };
    
    const handleStartDateChange = (date: string) => {        
        const formateDate = new Date(date);
        setStartDate(formateDate);
    };
    
    const handleEndDateChange = (dateString: string) => {        
        const date = new Date(dateString);
        setEndDate(date);
    };

    const fetchDataFromApi = async () => {
        try {
            setIsLoading(true);
            const today = new Date();
            const nextWeek = new Date(today);
            nextWeek.setDate(nextWeek.getDate() + 7);

            const response = await AppointmentServiceInternal.getappointment(startDate ?? today, endDate ?? nextWeek, search, null, "");
            if (response?.data.isSuccess) {
                
                setData(response.data.data);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data from API:', error);
        }
    }
    const getFullName = () => {
        const firstName = user.getUser() && user.getUser().first_name ? user.getUser().first_name : '';
        const lastName = user.getUser() && user.getUser().last_name ? user.getUser().last_name : '';
        return `${firstName} ${lastName}`;
    };

    useEffect(() => {
        const newStartIndex = (currentPage - 1) * recordsPerPage;
        const newEndIndex = newStartIndex + recordsPerPage;
        setStartIndex(newStartIndex);
        setEndIndex(Math.min(newEndIndex, data.length));
    }, [currentPage, data, recordsPerPage]);

    useEffect(() => {        
        fetchDataFromApi();
        setCurrentPage(1);
    }, [ search, startDate, endDate]);

    return (
        <div className="patient-dashboard">
            <div className="flex xs:block w-full dashboard-filter" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h1 className="text-3xl pb-4 mr-5">ITP Dashboard</h1>
                <div style={{fontSize:"larger"}}>
                    <span><strong>ITP User Name :</strong></span>
                    <span> {getFullName()} </span>
                </div>
            </div>

            <div className="flex relative">
                <div className="justify-between flex">
                    <span>
                        <IconButton
                            aria-label="search"
                            size="large"
                            className="p-2"
                            disabled
                        >
                            <SearchIcon fontSize="inherit" />
                        </IconButton>
                    </span>                    
                    <SearchForm                     
                     onSearch = {handleSearchChangeDebounced}
                     onStartDateChange={handleStartDateChange}
                     onEndDateChange={handleEndDateChange}
                     />

                </div>
            </div>
            {isLoading && <FullPageLoader />}
            <>
                <div className="mt-5 overflow-auto">
                    <ITPTable
                        data={paginatedData ?? []}                        
                    />
                </div>
                {paginatedData?.length > 0 && data && (
                    <PaginationControl
                        totalRecords={data?.length}
                        recordsPerPage={recordsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                )}
            </>

        </div>
    );
}
export default ITPUserDashboard;