import Layout from "../../components/Layout";
import { Container, Stepper } from "@mui/material";
import Stappers from "../../components/Shared/Steppers";
function NewAppointMent() {
    return (
        <>
            <Layout>
                <Container className="p-5">
                        <Stappers />
                </Container>
            </Layout>
        </>
    )
}

export default NewAppointMent;