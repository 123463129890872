export enum AppRoutings {
    Login = "/login",
    ForgotPassword = "/forgot-password",
    SSOLogin = "/itp-login",
    ProviderDashboard = "/provider-dashboard",
    ITPDashboard = "/itp-dashboard",
    FacilityDashboard = "/facility-dashboard",
    CorrectionalFacilityGrid = "/correctional-facility-management",
    AddCorrectionalFacility = "/correctional-facility-detail",
    CorrectionalFacilityUserGrid = "/correctional-facility-user-management",
    AddCorrectionalFacilityUser = "/new-correctional-facility-user",
    EditCorrectionalFacilityUser = "/edit-correctional-facility-user",
    ITPUserGrid = "/itp-user-management",
    AddITPUser = "/itp-user-detail",
    ProviderGrid = "/provider-management",
    AddProvider = "/provider-detail",
    AppointmentForm = "/new-appointment",
    RequestTypeGrid = "/request-type-management"
}