import { Container } from "@mui/material";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { UserServiceInternal } from "../../services/user.service";
import { CorrectionalFacilityUserForm } from "../../components/CorrectionalFacilityUser/CorrectionalFacilityUserForm";
import { CorrectionalFacilityUserDto } from "../../models/correctionalFacilityUserDto";

function NewCorrectionalFacilityUser() {
    const [facilitUserDetail, setfacilityUserDetail] = useState<CorrectionalFacilityUserDto>(new CorrectionalFacilityUserDto());
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get("id");


    const getUserById = async () => {

        const response = await UserServiceInternal.getUserById(Number(id));
        const facilityUserDetail: CorrectionalFacilityUserDto = response.data.data;
        if (response.data.data) {
            facilityUserDetail.name = `${response.data.data.first_name} ${response.data.data.last_name}`;
            facilityUserDetail.correctionalFacilityId = response.data.data.id_correctional;
            setfacilityUserDetail(facilityUserDetail);
        }
        else {
            setfacilityUserDetail(new CorrectionalFacilityUserDto());
        }
    }

    useEffect(() => {
        if (id) {
            getUserById();
        }
        return () => {
            setfacilityUserDetail(facilitUserDetail);
        }
    }, [id]);
    return (
        <Layout>
            <Container className="p-5">
                <CorrectionalFacilityUserForm facilityUserDetail={facilitUserDetail} />
            </Container>
        </Layout>
    )
}
export default NewCorrectionalFacilityUser;