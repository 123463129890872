import { Button, Chip, Typography } from '@mui/material';
import React, { useState, ChangeEvent, useEffect } from 'react';

interface WeekdayDropdownProps {
  label: string;
  handleOpen: (weekday: keyof TimeSlot) => void;
  selectedTimeSlots: Record<string, string[]>; // Corrected type
  onSelectedTimeSlotsChange: (weekday: keyof TimeSlot, timeSlots: string[]) => void; // Adjusted the type
  onWeekdayCheckboxChange: (weekday: keyof TimeSlot) => void;
  selectedWeekdays: string[];
  setSelectedWeekdays: React.Dispatch<React.SetStateAction<string[]>>;
}

export type TimeSlot = {
  Sunday: Array<string>;
  Monday: Array<string>;
  Tuesday: Array<string>;
  Wednesday: Array<string>;
  Thursday: Array<string>;
  Friday: Array<string>;
  Saturday: Array<string>;
}

const WeekdayDropdown: React.FC<WeekdayDropdownProps> = ({ label, handleOpen, selectedTimeSlots, onSelectedTimeSlotsChange, onWeekdayCheckboxChange, selectedWeekdays, setSelectedWeekdays }) => {

  const weekdays = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];

  //const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState<Record<string, boolean>>(
    weekdays.reduce<Record<string, boolean>>((acc, day) => {
      acc[day] = true;
      return acc;
    }, {})
  );

  // const handleWeekdayChange = (weekday: keyof TimeSlot) => {
  //   const isSelected = selectedWeekdays.includes(weekday);
  //   const updatedSelectedWeekdays = isSelected
  //     ? selectedWeekdays.filter(day => day !== weekday)
  //     : [...selectedWeekdays, weekday];

  //   setSelectedWeekdays(updatedSelectedWeekdays);
  //   onWeekdayCheckboxChange(weekday);
  //   setIsButtonDisabled(prevState => ({
  //     ...prevState,
  //     [weekday]: isSelected,
  //   }));
  // };

  const handleWeekdayChange = (weekday: keyof TimeSlot) => {
    const wasSelected = selectedWeekdays.includes(weekday);
    if (!wasSelected) {
      const updatedSelectedWeekdays = [...selectedWeekdays, weekday];
      setSelectedWeekdays(updatedSelectedWeekdays);
      onWeekdayCheckboxChange(weekday);
      setIsButtonDisabled(prevState => ({
        ...prevState,
        [weekday]: false,
      }));
    } else {
      setSelectedWeekdays(prevSelectedWeekdays =>
        prevSelectedWeekdays.filter(day => day !== weekday)
      );
      onWeekdayCheckboxChange(weekday);
      setIsButtonDisabled(prevState => ({
        ...prevState,
        [weekday]: true,
      }));
      onSelectedTimeSlotsChange(weekday, []);
    }
  };

  const compareTimes = (timeA: string, timeB: string): number => {
    const [hoursA, minutesA, periodA] = timeA
      .match(/(\d+):(\d+)\s?([APMapm]{2})?/)!
      .slice(1);
    const [hoursB, minutesB, periodB] = timeB
      .match(/(\d+):(\d+)\s?([APMapm]{2})?/)!
      .slice(1);

    const normalizedHoursA =
      periodA && periodA.toUpperCase() === "PM" && parseInt(hoursA, 10) !== 12
        ? parseInt(hoursA, 10) + 12
        : parseInt(hoursA, 10);

    const normalizedHoursB =
      periodB && periodB.toUpperCase() === "PM" && parseInt(hoursB, 10) !== 12
        ? parseInt(hoursB, 10) + 12
        : parseInt(hoursB, 10);

    if (normalizedHoursA !== normalizedHoursB) {
      return normalizedHoursA - normalizedHoursB;
    }

    return parseInt(minutesA, 10) - parseInt(minutesB, 10);
  };

  const handleRemoveTimeSlot = (weekday: keyof TimeSlot, timeSlotToRemove: string) => {
    const updatedTimeSlots = selectedTimeSlots[weekday]?.filter(timeSlot => timeSlot !== timeSlotToRemove) || [];
    onSelectedTimeSlotsChange(weekday, updatedTimeSlots);
  };

  return (
    <div>
       <Typography variant="subtitle2" sx={{ marginTop: 2, color:"gray"}}>
          {label}
       </Typography>
      {weekdays.map((weekday) => (
        <div key={weekday} className="weekday-dropdown mt-3 align-items-center w-full row md:flex-nowrap">
          <div className='col-md-2 col-4'>
            <label>
              <input
                type="checkbox"
                checked={selectedWeekdays.includes(weekday)}
                onChange={() => handleWeekdayChange(weekday as keyof TimeSlot)}
              />
              <span className="px-2">
                {weekday}
              </span>
            </label>
          </div>
          <div className='col-md-8 col-8' style={{ border: "1px solid #c4c4c4", height: "70px", overflow: "auto" }}>
            {selectedTimeSlots[weekday]?.sort(compareTimes).map(timeSlot => (
              // timeSlot && (
              <Chip className='ms-1 mt-1 mb-1' label={timeSlot} onDelete={() => handleRemoveTimeSlot(weekday as keyof TimeSlot, timeSlot)}/>
              // )
            ))}
          </div>

          <div className='col-md-2 col-4 ms-2'>
            <Button variant="contained" disabled={isButtonDisabled[weekday] && !selectedWeekdays.includes(weekday)} onClick={() => handleOpen(weekday as keyof TimeSlot)}>+</Button>
            {/* <Button variant="contained" disabled={isButtonDisabled[weekday]} onClick={() => handleOpen(weekday as keyof TimeSlot)}>+</Button> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WeekdayDropdown;