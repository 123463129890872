import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Card, Row, Col } from "react-bootstrap";
import { TextField, Button, Grid, Typography, Box } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserServiceInternal } from "../../services/user.service";
import { toast } from 'react-toastify';
import { ITPUserDto } from '../../models/ITPUserDtos';
import { useNavigate } from 'react-router-dom';
import { AppRoutings } from '../../enums/app-routing';
import CustomTextField from '../FormElements/CommonTextField';

interface Props {
    itpUserDetail: ITPUserDto;
}

export const ITPUserForm: FC<Props> = ({ itpUserDetail }) => {
    const navigate = useNavigate();
    const [itpUserDetailContent, setItpUserDetailContent] = useState<ITPUserDto>(new ITPUserDto());

    const validationSchema = yup.object().shape({
        email: yup.string().email("Email not valid.").required("Email Address is required"),
        name: yup.string().required("User Name is required"),
    });

    const methods = useForm<ITPUserDto>({
        defaultValues: new ITPUserDto(),
        mode: "onChange",
        resolver: yupResolver(validationSchema)
    });

    const handleSaveITPUser = async (data: ITPUserDto) => {
        try {
            if (data.userId === -1) {
                data.userId = null as any;
                await UserServiceInternal.saveITPUser(data);
                toast.success("ITP User Saved successfully.");
            }
            else {                
                const urlSearchParams = new URLSearchParams(window.location.search);
                const id = urlSearchParams.get("id");
                await UserServiceInternal.updateITPUser(data, id)
                toast.success("ITP User Updated successfully.");
            }
            navigate(AppRoutings.ITPUserGrid);
        }
        catch (error: any) {
            toast.error("Error while updating ITP User detail, please try again!");
        }
    }

    useEffect(() => {
        if (itpUserDetail && itpUserDetail.userId !== -1) {
            setItpUserDetailContent(itpUserDetail)
            methods.reset(itpUserDetail);
        } else {
            methods.reset(new ITPUserDto());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itpUserDetail]);


    return (
        <>
            <FormProvider {...methods}>
                <Grid className="d-flex justify-content-center">
                    <Card style={{ width: '80%' }} className="my-2">
                        <Card.Body>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography>
                                        Name:
                                    </Typography>
                                    <CustomTextField
                                        name="name"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography>
                                        Email Address:
                                    </Typography>
                                    <CustomTextField
                                        name="email"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "end" }}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        navigate(AppRoutings.ITPUserGrid)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Box sx={{ marginLeft: "10px" }}>
                                    <Button
                                        variant="contained"
                                        onClick={methods.handleSubmit((formData: ITPUserDto) =>
                                            handleSaveITPUser(formData)
                                        )}
                                    >
                                        {itpUserDetailContent.userId !== -1 ? "Update" : "Save"}
                                    </Button>
                                </Box>
                            </Box>
                        </Card.Body>
                    </Card>
                </Grid>
            </FormProvider >
        </>
    );
};