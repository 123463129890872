import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { CustomTextAreaProps } from "./CustomTestArea.props";


const CustomTextArea: React.FC<CustomTextAreaProps> = ({
    name,
    label,
    variant = "outlined",
    required = false,
    className,
    isDisabled = false,
    rows = 4, // Default number of rows for textarea
    hasFocus = false,
    handleInputChange,
    fullWidth,
}) => {
    const methods = useFormContext();
    const [previousValue, setPreviousValue] = useState<string>("");

    return (
        <Controller
            name={name}
            control={methods.control}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <TextField
                    fullWidth={fullWidth || false}
                    required={required}
                    multiline
                    rows={rows}
                    variant={variant}
                    size="small"
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        onChange(event.target.value);
                        if (
                            handleInputChange &&
                            (hasFocus ? previousValue !== event.target.value.trim() : true)
                        ) {
                            handleInputChange(event.target.value.trim());
                        }
                        setPreviousValue("");
                    }}                   
                    value={value || ""}
                    label={label}
                    error={!!error}
                    inputRef={ref}
                    helperText={error ? error.message : null}
                    className={className}
                    disabled={isDisabled}
                    autoComplete="off"
                    onFocus={(event: React.FocusEvent<HTMLTextAreaElement>) => {
                        if (hasFocus) {
                            setPreviousValue(event.target.value);
                        }
                    }}                    
                />
            )}
        />
    );
};

export default CustomTextArea;