import { render } from "@testing-library/react";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import "./Header.css";
import Jail_LOGO from "../../../assets/images/jailtele-logo.png";
import Jail_LOGO1 from "../../../assets/images/Jail-TeleHealthLogo.png";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  return (
    <div>
      <section className="header fixed top-0 left-0 w-full z-10 bg-white">
        <section className="header-top">
          <section className="header-top__logo hidden md:flex">
            <span
              className="header-logo"
              onClick={() => {
                //navigate("/facility-dashboard");
              }}
            >
              <img className="logo" src={Jail_LOGO1} style={{ height: "80px" }} />
            </span>
          </section>
          <section className="header-top__navbar">{<Navbar />}</section>
        </section>
      </section>
    </div>
  );
}

export default Header;
