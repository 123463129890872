import instance from "../api/http.common";

const _rootUrl: string = process.env.REACT_APP_BASE_URL ?? "";
const COMMON_URL: string = _rootUrl + "/CorrectionalFacalities";

class CorrectionalFacilityService {
    saveCorrectionalFacility = async (data: any) => {
        return await instance.post(COMMON_URL + "/SaveCorrectionalFacalities", data);
    };

    updateCorrectionalFacility = async (data: any, id: string | null) => {
        return await instance.post(COMMON_URL + "/UpdateCorrectionalFacalities", data, { params: { correctionalFacalityId: id } });
    };

    deleteCorrectionalFacility = async (userId: number) => {
        return await instance.delete(COMMON_URL + "/DeleteCorrectionalFacality", { params: { userId: userId } })
    };

    getCorrectionalFacilitybyid = async (id: number) => {
        return await instance.get(COMMON_URL + "/GetCorrectionalFacilityById", { params: { id: id } });
    };

    getCorrectionalFacilities = async () => {
        return await instance.get(COMMON_URL + "/GetAllFacilities");
    };

    getCorrectionalFacilitiesForProvider = async () => {
        return await instance.get(COMMON_URL + "/GetAllCorrectionalFacalities");
    };

    getFacilityDetailByUserId = async (id: number) => {
        return await instance.get(COMMON_URL + "/GetFacilityByUserId", { params: { userId: id } });
    };
}
export const CorrectionalFacilityServiceInternal = new CorrectionalFacilityService();