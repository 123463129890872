import { Box, Button, Card, FormControl, Grid, InputAdornment, InputLabel, List, OutlinedInput, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import PersonIcon from '@mui/icons-material/Person';
import UserThreeDots from "../../components/Shared/UserThreeDots";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { useEffect, useState } from "react";
import { UserServiceInternal } from "../../services/user.service";
import FullPageLoader from "../../components/Shared/FullPageLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppRoutings } from "../../enums/app-routing";
import userStore from "../../store/store";

const CorrectionalFacilityUserManagement = () => {

    const { user }: any = userStore();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchDataFromApi = async () => {
        try {
            setIsLoading(true);
            const response = await UserServiceInternal.getuserlist();
            if (response?.data.isSuccess) {
                let filteredData = [];
                if (user.id_role === 2) {
                    filteredData = response.data.data.filter((item: { id_correctional: number; }) => item.id_correctional === user.id_correctional);
                } else {
                    filteredData = response.data.data.filter((item: { id_role: number; }) => item.id_role === 2);
                }
                setData(filteredData);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data from API:', error);
        }
    }

    const navigate = useNavigate();
    const handleClick = (e: any) => {
        navigate(AppRoutings.AddCorrectionalFacilityUser);
    };

    const handleEditClick = (userId: number) => {
        navigate(`${AppRoutings.EditCorrectionalFacilityUser}?id=${userId}`);
    };

    const handleDeleteFacilityUser = async (userId: number) => {
        const response = await UserServiceInternal.deleteUser(userId);
        if (response?.data.isSuccess) {
            fetchDataFromApi();
            toast.success("Deleted Successfully")
        }
    };

    useEffect(() => {
        fetchDataFromApi();
    }, []);

    return (
        <Layout>
            {isLoading && <FullPageLoader />}
            <Typography
                sx={{
                    maxWidth: "100%",
                    minHeight: "70%",
                    maxHeight: "100%",
                    padding: 3,
                    margin: 2,
                    textAlign: "center",
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                    <FormControl sx={{ m: 1, width: '50%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            onChange={(e: any) => {
                                //InputHandler(e);
                            }}
                            onKeyDown={(e: any) => {
                                //onKeyDownHandler(e);
                            }}
                            placeholder="Search here"
                            endAdornment={
                                <InputAdornment position="end" sx={{ cursor: "pointer " }}>
                                    <SearchSharpIcon
                                        onClick={() => {
                                            //  setSearchQueryString(searchText);
                                        }}
                                    />
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                    <Button onClick={handleClick} sx={{ height: "50px" }} variant="contained"> + New Facility User</Button>
                </Box>
                <div>
                    <List sx={{ margin: "auto", textAlign: "center", width: "95%", marginTop: "5px" }}>
                        <Grid container sx={{ gridAutoRows: "1fr" }} rowSpacing={2} spacing={2} className="gridManagementScreen">
                            {
                                data && data.length > 0 ?
                                    (
                                        data.map((list: any) => {
                                            return (
                                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                                    <Card elevation={3} sx={{ p: 2, height: "95%" }} className='PendingRequestItem'>
                                                        <div>
                                                            <Box sx={{ display: "flex", mb: 3, justifyContent: "space-between" }}>
                                                                <div>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "start" }}>
                                                                        <div>
                                                                            {
                                                                                <PersonIcon sx={{ height: "80px", width: "80px", border: "2px solid", borderRadius: "50px" }} />
                                                                            }
                                                                        </div>
                                                                        <div className='userInformation'>
                                                                            <div className='userName'>{list.first_name}  {list.last_name}</div>
                                                                            <div className='userEmail'><b>{list.email}</b></div>
                                                                        </div>
                                                                    </Box>
                                                                </div>
                                                                <div>
                                                                    <UserThreeDots onEditClick={handleEditClick} onDeleteClick={handleDeleteFacilityUser} userId={list.id} />
                                                                </div>
                                                            </Box>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                        )
                                    ) :
                                    (
                                        <Grid container sx={{ justifyContent: "center", mt: "40px" }}>
                                            {!isLoading && <label>User List not Found</label>}
                                        </Grid>
                                    )
                            }
                        </Grid>
                    </List>
                </div>
            </Typography>
        </Layout>
    )
}
export default CorrectionalFacilityUserManagement;