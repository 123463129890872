import { toast } from "react-toastify";
import { ProviderServiceInternal } from "../../services/provider.service";

export class filterModel {
    search: string = "";
    startDate?: string = "";
    endDate?: string = "";
    inmateDob?: Date | null = null;
    stateSearch?: string = "";
}

export const today = new Date();

export const nextWeek = new Date(today);
nextWeek.setDate(nextWeek.getDate() + 7);

export const phoneNumberFormat = ("(###)-###-####");

export const zipCodeFormat = /^\d{5}(?:[-\s]\d{4})?$/;

export const handleJoinMeeting = async (providerId: number) => {
    const response = await ProviderServiceInternal.getproviderbyuserid(providerId);
    let sessionLink = response.data.data[0].sessionLink;

    if (response?.data.isSuccess) {
        window.open(sessionLink, '_blank');
    }
    else {
        toast.error("getting error while fetching link");
    }
}

export const isMeetingButtonEnabled = (appointmentDatetime: string, appointmentTypeId: number): boolean => {

    const appointmentDate = new Date(appointmentDatetime);

    // Get the current date and time
    const currentDate = new Date();

    // Check if the current datetime is before the appointment datetime
    if (currentDate < appointmentDate) {
        return false;
    }
    let endTime: Date;
    if (appointmentTypeId === 1) {
        endTime = new Date(appointmentDate.getTime() + 60 * 60000); // Add 60 minutes
    } else if (appointmentTypeId === 2) {
        endTime = new Date(appointmentDate.getTime() + 30 * 60000); // Add 30 minutes
    } else {

        return false;
    }
    return currentDate < endTime;
};
