import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import NoRecordFoundComponent from "../Shared/NoRecords";
import { FileUploadServiceInternal } from "../../services/fileUpload.service";
import { toast } from 'react-toastify';
import FullPageLoader from "../Shared/FullPageLoader";
import DownloadIcon from '@mui/icons-material/Download';


interface FileUploadData {
    appointmentid: number,
    blobstoragepath: string,
    created: string,
    uploadedname: string,
    filename: string,
    createdby: number,
    isExcluded: boolean
}

interface PatientTableProps {
    data: FileUploadData[];
}

const FacilityTemplatesTable = ({ data }: PatientTableProps) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleDownloadFile = async (fileName: string) => {
        setIsLoading(true);
        const response = await FileUploadServiceInternal.downloadFileTemplate(fileName);
        if (response?.data.isSuccess) {
            const data = response?.data.data;
            const byteCharacters = atob(data.contentBase64);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = data.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsLoading(false);
        }
        else {
            toast.error(response?.data.message)
            setIsLoading(false);
        }
        return response;
    }

    return (
        <React.Fragment>
        {isLoading && <FullPageLoader />}       
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '50%' }}>
                    <div className="flex xs:block w-full dashboard-filter" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h1 className="text-3xl pb-4 mr-5">Patient Documents</h1>
                    </div>
                    <Table style={{ width: '100%' }}>
                        <TableHead>
                            <TableRow style={{ color: "#A2A8BA", backgroundColor: "#F6F7FB" }}>
                                <TableCell >File Name</TableCell>
                                <TableCell >Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {data.length > 0 ? (
                            <TableBody>
                                {data.map((row: FileUploadData, index: number) => (
                                    <TableRow key={index} sx={{ borderRadius: "30px", backgroundColor: "#FFFFFF", borderWidth: "0" }}>
                                        <TableCell>{row.filename}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" onClick={() => handleDownloadFile(row.filename)} color="primary">
                                            <DownloadIcon />
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <NoRecordFoundComponent cellSpan={3} />
                            </TableBody>
                        )}
                    </Table>
                </div>
            </div>
            </React.Fragment>
    );
}
export default FacilityTemplatesTable;
