import React, { FC, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Card } from "react-bootstrap";
import { Button, Grid, Typography, Box } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserServiceInternal } from "../../services/user.service";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AppRoutings } from '../../enums/app-routing';
import CustomTextField from '../FormElements/CommonTextField';
import { CorrectionalFacilityServiceInternal } from '../../services/correctionFacilities.service';
import { CustomAutoComplete } from '../FormElements/CommonAutoComplete/CustomAutoComplete';
import { CorrectionalFacilityUserDto } from '../../models/correctionalFacilityUserDto';
import { IOption } from '../../models/select-option';
import userStore from "../../store/store";

interface Props {
    facilityUserDetail: CorrectionalFacilityUserDto;
}

export const CorrectionalFacilityUserForm: FC<Props> = ({ facilityUserDetail }) => {
    const navigate = useNavigate();
    const [facilityOptions, setFacilityOptions] = useState<IOption[]>([]);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get("id");

    const { user }: any = userStore();

    let validationSchema = yup.object().shape({
        email: yup.string().email("Email not valid.").required("Email Address is required"),
        name: yup.string().matches(/^[a-zA-Z]+ [a-zA-Z]+$/, 'Officer Name should contain both first name and last name').required('Officer Name is required'),
        correctionalFacilityId: yup.number()
            .required('Correctional Facility is required')
            .positive('Correctional Facility is required'),
        password: id === null ? yup
            .string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(
                /^(?=.*[!@#$%^&*])/,
                'Password must be 1 special character'
            )
            .matches(
                /^(?=.*[0-9])/,
                'Password must be 1 number value'
            )
            .matches(
                /^(?=.*[A-Z])/,
                'Password must be 1 uppercase letter'
            )
            .matches(
                /^(?=.*[a-z])/,
                'Password must be 1 lowercase letter'
            ) : yup.string(),
    });

    const methods = useForm<CorrectionalFacilityUserDto>({
        defaultValues: new CorrectionalFacilityUserDto(),
        mode: "onChange",
        resolver: yupResolver(validationSchema)
    });

    const handleSaveFacilityUser = async (data: CorrectionalFacilityUserDto) => {
        try {
            if (data.userId === -1) {

                const response = await UserServiceInternal.saveFacilityUser(data);
                if (response?.data.isSuccess)
                {
                    toast.success(response?.data.message);
                }
                else{
                    toast.error(response?.data.message);
                }
                
            }
            else {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const id = urlSearchParams.get("id");
                const response = await UserServiceInternal.updateFacilityUser(data, id)
                if (response?.data.isSuccess)
                {
                    toast.success(response?.data.message);
                }
                else{
                    toast.error(response?.data.message);
                }
            }
            navigate(AppRoutings.CorrectionalFacilityUserGrid);
        }
        catch (error: any) {
            toast.error("Error while updating Facility User detail, please try again!");
        }
    }

    useEffect(() => {

        if (facilityUserDetail && facilityUserDetail.userId !== -1) {
            methods.reset(facilityUserDetail);
        } else {
            methods.reset(new CorrectionalFacilityUserDto());
            if (user.id_role === 2) {
                methods.setValue("correctionalFacilityId", user.id_correctional)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilityUserDetail]);

    useEffect(() => {
        const fetchFacilities = async () => {
            try {
                const response = await CorrectionalFacilityServiceInternal.getCorrectionalFacilitiesForProvider();

                if (response?.data.isSuccess && response.data.data) {

                    const options = response.data.data.map((facility: { id: number; name: string }) => ({
                        value: facility.id,
                        label: facility.name
                    }));
                    setFacilityOptions(options);
                } else {
                    console.error("Error fetching correctional facilities");
                }

            } catch (error) {
                console.error("An error occurred while fetching correctional facilities", error);
            }
        };
        fetchFacilities();
    }, []);

    return (
        <>
            <FormProvider {...methods}>
                <Grid className="d-flex justify-content-center">
                    <Card style={{ width: '80%' }} className="my-2">
                        <Card.Body>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography>
                                        Officer Name:
                                    </Typography>
                                    <CustomTextField
                                        name="name"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography>
                                        Email Address:
                                    </Typography>
                                    <CustomTextField
                                        name="email"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography>
                                        Correctional Facility:
                                    </Typography>
                                    <CustomAutoComplete
                                        name="correctionalFacilityId"
                                        options={facilityOptions}
                                        className="w-100"
                                        isDisabled={user.id_role === 2}
                                    />
                                </Grid>
                                {!id ?
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Typography>
                                            Password:
                                        </Typography>
                                        <CustomTextField
                                            name="password"
                                            fullWidth
                                            type='password'
                                        />
                                    </Grid> :
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                    </Grid>
                                }
                            </Grid>
                            <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "end" }}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        navigate(AppRoutings.CorrectionalFacilityUserGrid)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Box sx={{ marginLeft: "10px" }}>
                                    <Button
                                        variant="contained"
                                        onClick={methods.handleSubmit(handleSaveFacilityUser)}
                                    >{id === null ? "Save" : "Update"}
                                    </Button>
                                </Box>
                            </Box>
                        </Card.Body>
                    </Card>
                </Grid>
            </FormProvider >
        </>
    );
};