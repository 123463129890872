import { Container } from "@mui/material";
import Layout from "../../components/Layout";
import { CorrectionalFacilityDetailDto } from "../../models/correctionalFacilityDtos";
import { CorrectionalFacilityServiceInternal } from "../../services/correctionFacilities.service";
import { useEffect, useState } from "react";
import CorrectionalFacilityForm from "../../components/CorrectionalFacility/CorrectionalFacilityForm";

function NewCorrectionalFacility() {
    const [correctionalFacilityDetail, setCorrectionalFacilityDetail] = useState<CorrectionalFacilityDetailDto>(new CorrectionalFacilityDetailDto());

    const getCorrectionalFacilityById = async () => {
        try {
            const response = await CorrectionalFacilityServiceInternal.getCorrectionalFacilitybyid(Number(id));
            const correctionalFacilityDetail: CorrectionalFacilityDetailDto = response.data.data;
            if (response.data)
                setCorrectionalFacilityDetail(correctionalFacilityDetail);

        }
        catch (error) {
            setCorrectionalFacilityDetail(new CorrectionalFacilityDetailDto());
        }
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get("id");

    useEffect(() => {
        if (id)
            getCorrectionalFacilityById();

        return () => {
            setCorrectionalFacilityDetail(correctionalFacilityDetail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <Layout>
            <Container className="p-5">
                <CorrectionalFacilityForm  correctionalFacilityDetail={correctionalFacilityDetail}/>
            </Container>
        </Layout>
    )
}

export default NewCorrectionalFacility;