import axios from "axios";
import api from "./apiconfig";
import instance from "./http.common";

const _rootUrl: string = process.env.REACT_APP_BASE_URL ?? "";

export const login = async (data: any) => {
    return await axios.post(_rootUrl + "/Login", data);
};

export const externallogin = async(data : any) => {
    return await axios.post(_rootUrl + "/Login/ExternalLogin", data);
}

export const forgotpassword = async (data: any) => {
    return await axios.post(_rootUrl + "/ForgotPassword", data);
};

export const resetpassword = async (data: any) => {
    return await axios.post(_rootUrl + "/ResetPassword", data);
};