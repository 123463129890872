import LoginHeader from "./LoginHeader";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { externallogin } from "../../api/endpoints";
import AuthContext from "../../store/auth-context";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../store/store";
import FullPageLoader from "../Shared/FullPageLoader";

export default function ExternalLoginForm() {
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    const pstore: any = useStore();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
        },
        resolver: yupResolver(yup.object().shape({
            email: yup
                .string()
                .email("Enter must be a valid email")
                .required("Email is Required")
        })),
    });

    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            var response = await externallogin(data);
            if (response?.data.isSuccess) {
                const { token, user } = response.data.data;
                pstore.setUser(user);
                authCtx.login(token);
                if (user.id_role == 1) {
                    window.location.href = "/provider-dashboard";
                }
                else if (user.id_role == 3) {
                    window.location.href = "/itp-dashboard";
                }
                else {
                    window.location.href = "/facility-dashboard";
                }
            }
            else {
                setIsLoading(false);
                toast.error(response?.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data from API:', error);
        }
    };

    return (
        <>
            {isLoading && <FullPageLoader />}
            <form className="mt-4 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <LoginHeader />
                <div className="-space-y-px">
                    <div className="my-2">
                        <label htmlFor="email-address" className="sr-only">
                            Email address
                        </label>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    id="email-address"
                                    type="email"
                                    className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                    placeholder="Email address"
                                />
                            )}
                        />
                        {errors.email && (
                            <p className="text-red-600 text-sm">{errors.email.message}</p>
                        )}
                    </div>
                </div>
                <div>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-10 mb-4"
                        >
                            Login Using SSO
                        </button>
                    </div>
                    {/* <div className="text-center mt-3 mb-4">
                        <strong><a href="/login" className="font-medium text-blue-600 hover:text-blue-600" style={{textDecoration:"underline", fontSize:"15px"}}>Return to Sign In Page</a></strong>
                    </div> */}
                </div>
            </form>
        </>
    );
}
