import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FullPageLoader from "../../components/Shared/FullPageLoader";
import { FileUploadServiceInternal } from "../../services/fileUpload.service";
import { toast } from "react-toastify";
import "./../../components/Layout/Pagination/index.css";
import FacilityTemplatesTable from "../../components/PatientFiles/FacilityTemplatesTable";

function FacilityFileTemplates() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            const response = await FileUploadServiceInternal.getFileTemplates();
            if (response?.data.isSuccess) {
                const notExcludeFiles = response?.data.data.filter((item: any) => item.isExcluded === false);
                setData(notExcludeFiles);
                setIsLoading(false);
            } else {
                toast.error("No data found.");
            }
        }
        catch (error) {
            toast.error("Failed to fetch data. Please try again later.");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {isLoading && <FullPageLoader />}
            <Typography
                sx={{
                    maxWidth: "100%",
                    minHeight: "70%",
                    maxHeight: "100%",
                    padding: 3,
                    margin: 2,
                    textAlign: "center",
                }}
            >
                <div className="patient-dashboard">
                    {isLoading && <FullPageLoader />}
                    <div className="overflow-auto">
                        <FacilityTemplatesTable
                            data={data ?? []}
                        />
                    </div>
                </div>
            </Typography>
        </>
    );
}
export default FacilityFileTemplates;