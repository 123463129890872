import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  InputLabel,
  Select,
  Grid,
  TextField,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

interface TimeDateSlotModelProps {
  open: boolean;
  onClose: () => void;
  onAddTimeSlot: (selectedDate: string, selectedStartTime: string, selectedEndTime: string) => void;
  existingTimeSlots: string[];
}

const TimeDateSlotModel = ({
  open,
  onClose,
  onAddTimeSlot,
  existingTimeSlots
}: TimeDateSlotModelProps) => {

  const getCurrentDate = () => {
    const today = new Date();
    const localISODate = new Date(today.getTime() - (today.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    return localISODate;
  };
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [selectedStartTime, setSelectedStartTime] = useState('');
  const [selectedEndTime, setSelectedEndTime] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const generateTimeSlots = () => {
    const timeSlots = [];
    let currentDate = new Date(selectedDate); // Use selected date as the initial date
    currentDate.setHours(8, 0, 0, 0); // Set initial time to 8:00 AM

    while (currentDate.getHours() < 17) {
      const timeString = currentDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const formattedTimeString = timeString.replace(/\s/g, ' ');
      timeSlots.push({ value: formattedTimeString, label: formattedTimeString });
      currentDate.setTime(currentDate.getTime() + 30 * 60 * 1000); // Increment by 30 minutes
    }
    return timeSlots;
  };

  const timeSlots = generateTimeSlots();

  const resetForm = () => {
    setSelectedDate(getCurrentDate());
    setSelectedStartTime("08:00 AM");
    setSelectedEndTime("08:00 AM");
    setErrorMessage(null);
  };

  const handleAddTimeSlot = () => {
    
    // Check if the start time and end time are the same
    if (selectedStartTime === selectedEndTime) {
        setErrorMessage("Start time and end time cannot be the same!");
        return;
    }

  const parseDateTime = (dateTimeString: string): dayjs.Dayjs | null => {
        try {                  
            const parsedDateTime = dayjs(dateTimeString, ['YYYY-MM-DD hh:mm A', 'YYYY/MM/DD hh:mm']); 
            if (!parsedDateTime.isValid()) {
                throw new Error("Invalid date/time format");
            }
            return parsedDateTime;
        } catch (error) {
            console.error("Error parsing date:", error);
            return null;
        }
    };

    const parseSelectedStart = parseDateTime(selectedDate + " " + selectedStartTime);
    const parseSelectedEnd = parseDateTime(selectedDate + " " + selectedEndTime);

    // Check if the selected start and end times are valid
    if (!parseSelectedStart || !parseSelectedEnd) {
        setErrorMessage("Invalid date/time format");
        return;
    }

    const isOverlap = existingTimeSlots.some((existingSlot) => {
        const [existingStartDate, ...times] = existingSlot.split(" ");
        const existingTimes = times.join(" ");
        const [existingStart, existingEnd] = existingTimes.split("-").map(time => time.trim());
      
        const parseExistingStart = parseDateTime(existingStartDate + " " + existingStart);
        const parseExistingEnd = parseDateTime(existingStartDate + " " + existingEnd);

        // Check if the existing start and end times are valid
        if (!parseExistingStart || !parseExistingEnd) {
            console.error("Invalid date/time format in existing slot:", existingSlot);
            return false; // Skip this slot
        }

        const isOverlapStart = parseSelectedStart.isBetween(parseExistingStart, parseExistingEnd) ||
            parseSelectedEnd.isBetween(parseExistingStart, parseExistingEnd) ||
            parseSelectedStart.isSame(parseExistingStart) || parseSelectedEnd.isSame(parseExistingEnd);

        // Handle the case where the start time of the new slot is equal to the end time of an existing slot
        const isAdjacentToExistingEnd = parseSelectedStart.isSame(parseExistingEnd) ;

        return isOverlapStart && !isAdjacentToExistingEnd;
    });

    if (isOverlap) {
        setErrorMessage("Time slots already exist for the given time!");
        return;
    }
    // If no overlap, add the time slot
    onAddTimeSlot(selectedDate, selectedStartTime, selectedEndTime);
    resetForm();
};

  const handleDateChange = (event: { target: { value: any } }) => {
    setSelectedDate(event.target.value);
  };
  const handleTimeChange = (event: { target: { value: any; }; }) => {    
    const selectedTime = event.target.value;
    setSelectedStartTime(selectedTime);
    setSelectedEndTime(selectedTime);
  };

  const compareTimes = (timeA: string, timeB: string): number => {
    if (!timeA || !timeB) {
      return 0;
    }
    const [hoursA, minutesA, periodA] = timeA.match(/(\d+):(\d+)\s?([APMapm]{2})?/)!.slice(1);
    const [hoursB, minutesB, periodB] = timeB.match(/(\d+):(\d+)\s?([APMapm]{2})?/)!.slice(1);

    const normalizedHoursA = periodA && periodA.toUpperCase() === 'PM' && parseInt(hoursA, 10) !== 12
      ? parseInt(hoursA, 10) + 12
      : parseInt(hoursA, 10);

    const normalizedHoursB = periodB && periodB.toUpperCase() === 'PM' && parseInt(hoursB, 10) !== 12
      ? parseInt(hoursB, 10) + 12
      : parseInt(hoursB, 10);

    if (normalizedHoursA !== normalizedHoursB) {
      return normalizedHoursA - normalizedHoursB;
    }

    return parseInt(minutesA, 10) - parseInt(minutesB, 10);
  };

  useEffect(() => {
    if (!open) {
      setErrorMessage(null);
    }
    resetForm();
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Add Date Time Slot
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <InputLabel htmlFor="date">Date</InputLabel>
              <TextField
                id="date"
                type="date"
                fullWidth
                value={selectedDate}
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: getCurrentDate(), // Set the minimum allowed date
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid className="mt-1" container spacing={2}>
          <Grid item xs={6}>
            <Typography>
              <InputLabel htmlFor="begin-time">Begin Time</InputLabel>
              <Select
                native
                fullWidth
                inputProps={{
                  name: "begin-time",
                  id: "begin-time",
                }}
                value={selectedStartTime}
                onChange={handleTimeChange}
              >
                {timeSlots.map((timeSlot) => (
                  <option key={timeSlot.value} value={timeSlot.value}>
                    {timeSlot.label}
                  </option>
                ))}
              </Select>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <InputLabel htmlFor="end-time">End Time</InputLabel>
              <Select
                native
                fullWidth
                inputProps={{
                  name: "end-time",
                  id: "end-time",
                }}
                value={selectedEndTime}
                onChange={(event) => setSelectedEndTime(event.target.value)}
              >
                {timeSlots.map((timeSlot) => {
                  const isDisabled =
                    selectedStartTime !== null &&
                    compareTimes(timeSlot.value, selectedStartTime) < 0;

                  return (
                    <option
                      key={timeSlot.value}
                      value={timeSlot.value}
                      disabled={isDisabled}
                    >
                      {timeSlot.label}
                    </option>
                  );
                })}
              </Select>
            </Typography>
          </Grid>
        </Grid>
        <Grid mt={2}>
          {errorMessage && (
            <Typography color="error" variant="subtitle2">
              {errorMessage}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleAddTimeSlot}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TimeDateSlotModel;