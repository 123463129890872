import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function LoginFormExtra() {

  const navigate = useNavigate();
  
  const forgotPassword = () => {
    navigate("/forgot-password");
  }

  return (

    <div className="flex items-center justify-between gap-3">
      <div className="flex items-center gap-1">
        {/* <input
          id="remember-me"
          name="remember-me"
          type="checkbox"
          className="h-4 w-4 text-blue-600 focus:ring-purple-500 border-gray-300 rounded"
        />
        <label htmlFor="remember-me" className="block text-sm text-gray-900">
          Remember me
        </label> */}
      </div>

      <div className="text-sm">
        <a onClick={forgotPassword} className="font-medium text-blue-600 hover:text-blue-600" style={{ cursor: 'pointer' }}>
          Forgot your password?
        </a>
      </div>
    </div>
  );
}
