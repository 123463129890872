import { AppRoutings } from "../enums/app-routing";

export const BUTTONS = {
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    CONTINUE: "Continue",
    YES: "Yes",
    NO: "No",
    NEXT: "Next",
    PREVIOUS: "Previous"
  };

  export const APPOINTMENT_TYPE_ID = {
    FIRSTTIMEBEINGSEEN: 1,
    FOLLOWUP: 2,
  }

export const EXCLUDE_NAVBAR = [AppRoutings.Login];

export const RECORDS_PER_PAGE = 5;