import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import NoRecordFoundComponent from "../Shared/NoRecords";
import { FileUploadServiceInternal } from "../../services/fileUpload.service";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import FullPageLoader from "../Shared/FullPageLoader";
import ConfirmationModal from "../Shared/ConfirmationModal";
import { BUTTONS } from "../../constants";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';

interface FileUploadData {
    appointmentid: number,
    blobstoragepath: string,
    created: string,
    uploadedname: string,
    filename: string,
    createdby: number,
    isExcluded: boolean
}

interface PatientTableProps {
    data: FileUploadData[];
}

const PatientFileTable = ({ data: initialData }: PatientTableProps) => {

    const [file, setFile] = useState<File | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<FileUploadData[]>([]);
    const [openModel, setOpenModel] = useState(false);

    const handleDownloadFile = async (fileName: string) => {
        setIsLoading(true)
        const response = await FileUploadServiceInternal.downloadFileTemplate(fileName);
        if (response?.data.isSuccess) {
            const data = response?.data.data;
            const byteCharacters = atob(data.contentBase64);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = data.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsLoading(false)
        }
        else {
            toast.error(response?.data.message)
            setIsLoading(false);
        }
        return response;
    }

    const handleFileUpload = async (file: File) => {
        
        const formData = new FormData();
        formData.append('file', file);
        setIsLoading(true)
        const response = await FileUploadServiceInternal.uploadFileTemplates(formData);
        if (response?.data.isSuccess) {
            toast.success(response?.data.message);
            const newResponse = await FileUploadServiceInternal.getFileTemplates();
            setData(newResponse?.data.data)
            setIsLoading(false)

            // Clear the file input value
            const inputFileElement = document.getElementById('model-upload') as HTMLInputElement;
            if (inputFileElement) {
                inputFileElement.value = '';
            }
            setFile(null);
        }
        else {
            toast.error(response?.data.message);
            setIsLoading(false)
        }
    };

    const handleUpload = () => {
        if (file) {
            handleFileUpload(file);
        }
        else{
            toast.error("Please select a file to upload");
        }
    };

    const handleCheckboxChange = async (filename: string, isChecked: boolean) => {
        await FileUploadServiceInternal.excludedFiles(filename, isChecked);
    };

    const handleRemoveFile = async (fileName: string) => {
        setSelectedFileName(fileName);
        setOpenModel(true);
    };

    const confirmDeleteFile = async () => {
        setIsLoading(true)
        const response = await FileUploadServiceInternal.removeFileTemplate(selectedFileName);
        if (response?.data.isSuccess) {
            // Remove the file from the data list
            const updatedData = data.filter(item => item.filename !== selectedFileName);
            setData(updatedData);
            toast.success(response?.data.message);
            setIsLoading(false);
        }
        else {
            toast.error(response?.data.message);
            setIsLoading(false)
        }
        setOpenModel(false);
    };

    useEffect(() => {
        setData(initialData);
    }, [initialData]);

    return (
        <React.Fragment>
            {isLoading && <FullPageLoader />}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '70%' }}>
                    <div className="flex xs:block w-full dashboard-filter" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h1 className="text-3xl pb-4 mr-5">Patient Documents</h1>
                    </div>
                    <div className="my-3 d-flex align-items-center flex-wrap">
                        <label htmlFor="model-upload" className="me-2">
                            Upload File:
                        </label>
                        <input type="file" id="model-upload" onChange={(e) => setFile(e.target.files?.[0] || null)} />
                        <Button className="md:ms-2 mt-2" variant="contained" color="info" onClick={handleUpload}>
                            <UploadIcon />
                            Upload
                        </Button>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow style={{ color: "#A2A8BA", backgroundColor: "#F6F7FB" }}>
                                <TableCell>File Name</TableCell>
                                <TableCell>Exclude</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {data.length > 0 ? (
                            <TableBody>
                                {data.map((row: FileUploadData, index: number) => (
                                    <TableRow key={index} sx={{ borderRadius: "30px", backgroundColor: "#FFFFFF", borderWidth: "0" }}>
                                        <TableCell>{row.filename}</TableCell>
                                        <TableCell>
                                            <Checkbox
                                                defaultChecked={row.isExcluded}
                                                onChange={(e) => handleCheckboxChange(row.filename, e.target.checked)}
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: '18px' }}>
                                            <Button variant="contained" onClick={() => handleDownloadFile(row.filename)} color="primary" className="mt-2">
                                                <DownloadIcon />
                                                Download
                                            </Button>
                                            <Button className="mt-2 ms-2" variant="contained" onClick={() => handleRemoveFile(row.filename)} color="error">
                                                <DeleteIcon />
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <NoRecordFoundComponent cellSpan={3} />
                            </TableBody>
                        )}
                    </Table>
                </div>
            </div>
            <ConfirmationModal
                open={openModel}
                title={"Are you sure you want to delete?"}
                onConfirm={() => {
                    setOpenModel(false);
                    confirmDeleteFile();
                }}
                onClose={() => {
                    setOpenModel(false);
                }}
                confirmText={BUTTONS.YES}
                cancelText={BUTTONS.NO}
            />
        </React.Fragment >
    );
}
export default PatientFileTable;
