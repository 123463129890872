import { render } from "@testing-library/react";
import Header from "./Header/Header";
import Footer from "./Footer/footer";

function Layout({ children }: any) {
  return (
    <>
      <div>
        <Header />
      </div>
      <main className="bg-indigo-50 pt-80">{children}</main>
      <div className="pt-4">
        <Footer />
      </div>
    </>
  );
}

export default Layout;
