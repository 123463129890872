import { Container } from "@mui/material";
import Layout from "../../components/Layout";
import { RequestTypeForm } from "../../components/AppointmentType/RequestTypeForm";

function NewRequestType() {
  return (
    <Layout>
      <Container className="p-5">
        <RequestTypeForm />
      </Container>
    </Layout>
  );
}
export default NewRequestType;
