import Container from "react-bootstrap/Container";
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import "./Navbar.css";
import src from "../../../assets/images/user.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useStore from "../../../store/store";

function NavbarMenu() {
  const navigate = useNavigate();
  const user: any = useStore();

  const handleLogout = () => {
    Cookies.remove("access-token");
    navigateToLogin();
  };

  const navigateToLogin = () => {
    const userRole = user.getUser().id_role;
    if (userRole === 2) {
      navigate("/login");
      window.location.reload();
    } else {
      navigate("/itp-login");
      window.location.reload();
    }
  };

  const handleNavigateToPatient = () => {
    navigate("/facility-dashboard");
  };

  const handleNavigateToProvider = () => {
    navigate("/provider-dashboard");
  };

  const handleNavigateToITP = () => {
    navigate("/itp-dashboard");
  };
  return (
    <>
      <Navbar data-bs-theme="light">
        <Container>
          <Nav className="ml-auto">
            {user.getUser() && user.getUser().id_role == 2 && (
              <Nav.Link onClick={handleNavigateToPatient}>Dashboard</Nav.Link>
            )}
            {user.getUser() && user.getUser().id_role == 1 && (
              <Nav.Link onClick={handleNavigateToProvider}>
                Provider Dashboard
              </Nav.Link>
            )}
            {user.getUser() && user.getUser().id_role == 3 && (
              <Nav.Link onClick={handleNavigateToITP}>ITP Dashboard</Nav.Link>
            )}

            {user.getUser() && user.getUser().id_role == 3 && (
              <Nav>
                <NavDropdown
                  title="Admin"
                  id="adminDropDown"
                  className="management-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => navigate("/provider-management")}
                  >
                    Provider Management
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() =>
                      navigate("/correctional-facility-management")
                    }
                  >
                    Correctional Facility <p>Management</p>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() =>
                      navigate("/correctional-facility-user-management")
                    }
                  >
                    Correctional Facility <p>User Management</p>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/patient-file-templates")}
                  >
                    Patients File Templates
                  </NavDropdown.Item>

                  {/* <NavDropdown
                    title="ITP Management"
                    id="itpManagementDropDown"
                    className="management-dropdown"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <NavDropdown.Item
                      onClick={() => navigate("/ITP-user-management")}
                    >
                      ITP User
                    </NavDropdown.Item>
                  </NavDropdown> */}

                  <Dropdown drop="down">
                    <Dropdown.Toggle as="div" className="dropdown-item" id="ITPManagementDropdown">
                      ITP Management
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate('/ITP-user-management')}>ITP User</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>


                  <NavDropdown.Item
                    onClick={() => navigate("/request-type-management")}
                  >
                    Request Type Configuration
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}

            {user.getUser() && user.getUser().id_role == 2 && (
              <Nav>
                <NavDropdown
                  title="Admin"
                  id="adminDropDown"
                  className="management-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() =>
                      navigate("/correctional-facility-user-management")
                    }
                  >
                    Correctional Facility <p>User Management</p>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}

            <NavDropdown
              className="mr-auto"
              title={
                user.getUser() &&
                user.getUser().first_name && (
                  <div className="inline-flex gap-x-2">
                    <p>
                      {user.getUser().first_name +
                        " " +
                        (user.getUser().last_name && user.getUser().last_name)}
                    </p>
                    <img className="thumbnail-image" src={src} alt="user pic" />
                  </div>
                )
              }
            >
              <NavDropdown.Item onClick={handleLogout}>
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarMenu;
