import packageJdon from "../../../../package.json";

function Footer() {
  return (
    <section className="fixed bottom-0 text-xs w-full bg-white pt-1 pr-3">
      <div className="text-gray-400 text-right">
        <p className="mb-1">
          Version {packageJdon.version} | All copyrights reserved, ADEVCO INC. {new Date().getFullYear()} ©
        </p>
      </div>
    </section>
  );
}

export default Footer;