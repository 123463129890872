export class CorrectionalFacilityDetailDto {
    correctionalFacilityId?: number = -1;
    name: string = "";
    officerName: string = "";
    email: string = "";
    phoneNumber: string = "";
    address: string = "";
    city: string = "";
    zipCode: string = "";
    state: string = "";
    ipList?: string = "";
    isHeldFor?:boolean =false;
}

export interface CorrectionalFacilityListDto {
    id: number;
    name: string;
    email: string;
    phone_number: string;
    address: string;
    is_active: boolean;
    createdby: number;
    createdon: string;
    updatedby: number;
    updatedon: string;
}