import axios from "axios";

const _rootUrl: string = process.env.REACT_APP_API_ROOT_URL ?? "";

const defaultOptions = {
  baseUrl: _rootUrl,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

// Create instance
let instance = axios.create(defaultOptions);

export default instance;
