import instance from "../api/http.common";

const _rootUrl: string = process.env.REACT_APP_BASE_URL ?? "";
const COMMON_URL: string = _rootUrl + "/FileUpload";

class FileUploadService {
    uploadFile = async (data: any) => {
        return await instance.post(COMMON_URL + "/Upload", data);
    };

    uploadFileTemplates = async (data: any) => {
        return await instance.post(COMMON_URL + "/UploadTemplates", data);
    };

    getAllUploadedFile = async (appointmentId: number) => {
        return await instance.get(COMMON_URL + "/GetListofUploadFile", { params: { appointmentId: appointmentId } });
    };

    downloadFile = async (fileName: string) => {
        return await instance.get(COMMON_URL + "/DownloadFile", { params: { fileName: fileName } });
    }

    downloadFileTemplate = async (fileName: string) => {
        return await instance.get(COMMON_URL + "/DownloadFileTemplate", { params: { fileName: fileName } });
    }

    getFileTemplates = async () => {
        return await instance.get(COMMON_URL + "/GetListOfFileTemplates");
    };

    excludedFiles = async (filename: string, isExcluded: boolean) => {
        return await instance.get(COMMON_URL + "/ExcludedFiles", { params: { fileName: filename, isExcluded: isExcluded } });
    };

    removeFileTemplate = async (fileName: string) => {
        return await instance.delete(COMMON_URL + "/RemoveFileTemplate", { params: { fileName: fileName } });
    }
}
export const FileUploadServiceInternal = new FileUploadService();