import { Container } from "@mui/material";
import Layout from "../../components/Layout";
import ProviderManagementForm from "../../components/Provider/ProviderManagementForm";

function NewProvider() {
    return (
        <Layout>
            <Container className="p-5">
                <ProviderManagementForm />
            </Container>
        </Layout>
    )
}

export default NewProvider;