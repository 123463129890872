import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import React, { PropsWithChildren } from "react";

function CustomCard<T extends object>({
  children,
  isFitContent = false,
  ...props
}: any) {
  if (isFitContent) {
    return (
      <Box className="card-wrapper w-fit">
        <Card className="card mb-8" {...props}>
          {children}
        </Card>
      </Box>
    );
  } else {
    return (
      <Box className="card-wrapper">
        <Card className="card p-8 mb-8" {...props}>
          {children}
        </Card>
      </Box>
    );
  }
}

export default CustomCard;
