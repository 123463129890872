export const getImagePath = (imageNameWithExtension: string): string => {
    return require(`./${imageNameWithExtension}`);
};
export const chipCloseDefaultIcon = getImagePath("chip-close-default-icon.svg");
export const checkboxCheckedPrimaryIcon = getImagePath(
    "checkbox-checked-primary-icon.svg"
);
export const checkboxDefaultOutlineIcon = getImagePath(
    "checkbox-default-outline-icon.svg"
);

export const radioCheckedPrimaryIcon = getImagePath(
    "radio-checked-primary-icon.svg"
);
export const radioUncheckedDefaultIcon = getImagePath(
    "radio-unchecked-default-icon.svg"
);

export const closeWhiteIcon = getImagePath("close-white-icon.svg");