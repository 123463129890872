import { Box, TextField, IconButton, Tooltip } from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import { useEffect, useState } from "react";
import CustomDatePicker from "../FormElements/CommonDatePicker";
import { filterModel } from "../Shared/Share";
import CustomTextField from "../FormElements/CommonTextField";
import ClearIcon from '@mui/icons-material/Clear';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import useStore from "../../store/store";


interface SearchFormProps {
    onSearch: (value: string) => void;
    onStartDateChange: (value: string) => void;
    onEndDateChange: (value: string) => void;
    onInmateDobChange?: (value: string) => void;
    onSearchStateChange?: (value: string) => void;
    onHandleReset?: () => void;
}

function SearchForm({ onSearch, onStartDateChange, onEndDateChange, onInmateDobChange, onSearchStateChange, onHandleReset }: SearchFormProps) {
    const methods = useForm<filterModel>({
        defaultValues: new filterModel(),
        mode: "onChange",
    })
    const user: any = useStore();
    const userRole = user.getUser() && user.getUser().id_role ? user.getUser().id_role : '';
    const today = new Date();
    const nextWeek = new Date(today);
    nextWeek.setDate(nextWeek.getDate() + 7);
    
    useEffect(() => {
        methods.setValue("startDate", today.toString())
        methods.setValue("endDate", nextWeek.toString())
        methods.setValue("search", "")
        methods.setValue("inmateDob", null)
        methods.setValue("stateSearch", "")
    }, []);

    const handleReset = () => {
        methods.setValue("startDate", today.toString())
        methods.setValue("endDate", nextWeek.toString())
        methods.setValue("search", "")
        methods.setValue("inmateDob", null)
        methods.setValue("stateSearch", "")
        if (onHandleReset) {
            onHandleReset();
        }
    };

    return (<>
        <FormProvider {...methods}>
            <Box
                display="flex"
                alignItems="center"
                gap={"0.8rem"}
                flexDirection={{ xs: "column", sm: "row", md: "row", lg: "row" }}
            >
                <CustomTextField
                    label="Search"
                    name="search"
                    handleInputChange={(searchText: string) => onSearch(searchText)}
                    fullWidth
                />
                {userRole === 1 && (<>
                    <CustomDatePicker
                        label="Date of Birth"
                        name="inmateDob"
                        handleChange={(value: Date | null) => {
                            const stringValue = value ? value.toISOString().split('T')[0] : "";
                            if (onInmateDobChange) {
                                onInmateDobChange(stringValue);
                            }
                        }}
                    />
                    <CustomTextField
                        label="State"
                        name="stateSearch"
                        handleInputChange={(searchText: string) => {
                            if (onSearchStateChange) {
                                onSearchStateChange(searchText);
                            }
                        }}
                        fullWidth
                    />
                </>)}
                <CustomDatePicker
                    label="Start Date"
                    name="startDate"
                    handleChange={(value: Date | null) => {
                        const stringValue = value ? value.toISOString().split('T')[0] : "";
                        onStartDateChange(stringValue);
                    }}
                />
                <CustomDatePicker
                    label="End Date"
                    name="endDate"
                    handleChange={(value: Date | null) => {
                        const stringValue = value ? value.toISOString().split('T')[0] : "";
                        onEndDateChange(stringValue);
                    }}
                />
                {userRole == 1 &&
                    <Tooltip title="Reset">
                        <IconButton onClick={handleReset} style={{ color: 'blue' }}>
                            <RotateLeftIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        </FormProvider>
    </>)
}

export default SearchForm;