import instance from "../api/http.common";
import { ITPUserDto } from "../models/ITPUserDtos";
import { CorrectionalFacilityUserDto } from "../models/correctionalFacilityUserDto";

const _rootUrl: string = process.env.REACT_APP_BASE_URL ?? "";
const COMMON_URL: string = _rootUrl + "/Users";

class userService {
    getuserlist = async () => {
        return await instance.get(COMMON_URL);
    };

    saveITPUser = async (data: ITPUserDto) => {
        return await instance.post(COMMON_URL + "/SaveITPUser", data);
    };

    updateITPUser = async (data: ITPUserDto, id: string | null) => {
        return await instance.post(COMMON_URL + "/UpdateITPUser", data, { params: { userId: id } });
    };

    deleteUser = async (userId: number) => {
        return await instance.delete(COMMON_URL + "/DeleteUser", { params: { userId: userId } })
    };

    getUserById = async (id: number) => {
        return await instance.get(COMMON_URL + "/GetUserById", { params: { id: id } });
    };

    //------  Correctional Facility User  ----
    saveFacilityUser = async (data: CorrectionalFacilityUserDto) => {
        return await instance.post(COMMON_URL + "/SaveFacilityUser", data);
    };

    updateFacilityUser = async (data: ITPUserDto, id: string | null) => {
        return await instance.post(COMMON_URL + "/UpdateFacilityUser", data, { params: { userId: id } });
    };
}
export const UserServiceInternal = new userService();