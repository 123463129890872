import React from "react";

interface PaginationProps {
  totalRecords: number;
  recordsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  title?: string;
}

const PaginationControl: React.FC<PaginationProps> = ({
  totalRecords,
  recordsPerPage,
  currentPage,
  onPageChange,
  title = "patients",
}) => {
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const lastPageBeforeEllipsis = totalPages - 2;

  const displayedPages =
    totalPages <= 5
      ? pageNumbers
      : currentPage <= 3
      ? [...pageNumbers.slice(0, 5), "...", totalPages]
      : currentPage >= lastPageBeforeEllipsis
      ? [1, "...", ...pageNumbers.slice(lastPageBeforeEllipsis - 2)]
      : [
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages,
        ];

  return (
    <div className="font flex items-center mt-3 xs:justify-center">
      <div className="mr-5 xs:hidden">
        Showing {recordsPerPage} {title} per page
      </div>
      <button
        type="button"
        className="mr-2 text-blue-500"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"}
      </button>

      <div className="page-numbers">
        {displayedPages.map((pageNumber, index) => (
          <button
            type="button"
            key={index}
            onClick={() => {
              if (pageNumber !== "...") {
                onPageChange(pageNumber as number);
              }
            }}
            className={pageNumber === currentPage ? "active" : ""}
          >
            <span>{pageNumber}</span>
          </button>
        ))}
      </div>

      <button
        type="button"
        className="ml-2 text-blue-500"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {">"}
      </button>
    </div>
  );
};

export default PaginationControl;
