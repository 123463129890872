import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";

export const getAuthentication = async (): Promise<string | undefined> => {
  const token = Cookies.get("access-token");
  return token;
};

export const isTokenExpired = (token: string | undefined): boolean => {
  if (token) {
    const expiryTime = getTokenExpirationTime(token);
    const currentTime = new Date().getTime() / 1000;
    if (expiryTime) {
      return currentTime > expiryTime;
    }
  }
  return false;
};

export const getTokenExpirationTime = (token: string) => {
  return (jwtDecode(token) as { exp: number }).exp;
};
