import instance from "../api/http.common";

const _rootUrl: string = process.env.REACT_APP_BASE_URL ?? "";
const COMMON_URL: string = _rootUrl + "/Provider";

class ProviderService {
    saveprovider = async (data: any) => {
        return await instance.post(COMMON_URL + "/SaveProvider", data);
    };
    
    updateprovider = async (data: any) => {
        return await instance.post(COMMON_URL + "/UpdateProvider", data);
    };
    
    getproviderbyuserid = async (id: number) => {
        return await instance.get(COMMON_URL + "/GetProviderDetailsByUserId", { params: { id: id } });
    };
    
    deleteprovider = async (userId: number) => {
        return await instance.get(COMMON_URL + "/DeleteProvider", { params: { userId: userId } })
    }
    
    assignProvider = async () => {
        return await instance.get(COMMON_URL + "/AssignProvider");
    };
}
export const ProviderServiceInternal = new ProviderService();