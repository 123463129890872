import React from "react";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { PatternFormat } from "react-number-format";

import { CustomPatternInputProps } from "./CustomPatternInput.Props";

export const CustomPatternInput: React.FC<CustomPatternInputProps> = ({
	name,
	label,
	patternFormat,
	isDisabled = false,
	allowEmptyFormatting = false,
	fullWidth,
	className
}) => {

	const methods = useFormContext();
	return (
		<FormControl className={className}>
			<InputLabel
				error={!!methods.formState.errors[name]}
				disabled={!!isDisabled}
			>
				{label}
			</InputLabel>
			<Controller
				name={name}
				control={methods.control}
				render={({
					field: { onChange, value, ref },
					fieldState: { error },
				}) => (
					<PatternFormat
						customInput={OutlinedInput}
                        fullWidth={fullWidth ? fullWidth : false}
						label={label}
						error={!!error}
						inputRef={ref}
						value={value || ""}
						disabled={!!isDisabled}
						autoComplete="off"
						format={patternFormat}
						mask="_"
						allowEmptyFormatting={allowEmptyFormatting}
						onValueChange={(values) => {
							onChange(values.formattedValue);
						}}
						onBlur={(values) => {
							onChange(values);
						}}
						className="pattern-input"
					/>
				)}
			/>
			{methods.formState.errors[name] && (
				<FormHelperText error>
					{(methods.formState.errors[name]?.message as React.ReactNode) || 'Error'}
				</FormHelperText>
			)}
		</FormControl>
	);
};
