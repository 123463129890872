import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { startOfMonth, isSameDay } from "date-fns"; // import isSameDay function

import { CustomDatePickerProps } from "./CustomDatePicker.Props";
import { useCustomDatePickerStyles } from "./useCustomDatePickerStyles";
import classNames from "classnames";

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
	name,
	label,
	minDate,
	maxDate,
	isDisabled,
	handleChange,
	holidayDates,
	availableWeekDays,
	isDateValidate
}) => {
	const methods = useFormContext();
	//const classes = useCustomDatePickerStyles();
	const isDateDisabled = (date: Date) => {
		const isHoliday = holidayDates?.some((holidayDate) => isSameDay(holidayDate, date));
		const dayOfWeek = date.toLocaleDateString(undefined, { weekday: 'long' });
		const dayAvailability = availableWeekDays && availableWeekDays[dayOfWeek as keyof typeof availableWeekDays];
		const isWeekdayAvailable = Array.isArray(dayAvailability) && dayAvailability.length > 0;
		return isHoliday || !isWeekdayAvailable;
	};

	return (
		<Controller
			name={name}
			control={methods.control}
			render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						className="mt-4"
						label={label}
						value={value}
						disabled={isDisabled}
						onChange={(value) => {
							onChange(value);
							if (handleChange) handleChange(value);
						}}
						inputRef={ref}
						maxDate={maxDate}
						minDate={minDate}
						inputFormat="MM/dd/yyyy"
						//views={['year', 'month', 'day']}
						defaultCalendarMonth={minDate ? startOfMonth(minDate) : undefined}
						shouldDisableDate={isDateValidate ? isDateDisabled : undefined} // disable dates based on holidayDates
						renderInput={(params :any) => (
							<TextField
								{...params}
							    className="date-picker"
								//className={classNames(classes.CustomDatePicker, 'date-picker')}
								size="small"
								error={!!methods.formState.errors[name] || !!error}
								autoComplete="off"
								helperText={error ? error.message : null}
								fullWidth
							/>
						)}
					/>
				</LocalizationProvider>
			)}
		/>
	);
};

export default CustomDatePicker;
