import LoginForm from "../../components/Login/LoginForm";
import CustomCard from "../../components/Shared/Card";
import { Grid } from "@mui/material";
import packageJson from "../../../package.json";

function Login() {
  return (
    <>
      <div className="w-full flex justify-center mt-3">
        <CustomCard isFitContent={true}>
          <Grid container className="justify-center">
            <Grid item xs={12} md={10}>
              <div className="w-full">
                <LoginForm />
              </div>
            </Grid>
          </Grid>
        </CustomCard>
      </div>
      <div className="text-xs pt-1 w-full text-center">
        <p className="text-gray-400">
          Version {packageJson.version} | All copyrights reserved, ADEVCO INC. {new Date().getFullYear()} ©
        </p>
      </div>
    </>
  );
}

export default Login;
