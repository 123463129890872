import { Container } from "@mui/material";
import Layout from "../../components/Layout";
import { ITPUserForm } from "../../components/ITPUser/ITPUserForm";
import { useEffect, useState } from "react";
import { ITPUserDto } from "../../models/ITPUserDtos";
import { UserServiceInternal } from "../../services/user.service";

function NewITPUser() {
  const [itpUserDetail, setItpUserDetail] = useState<ITPUserDto>(
    new ITPUserDto()
  );

  const getITPUserById = async () => {
    const response = await UserServiceInternal.getUserById(Number(id));
    const itpUserDetail: ITPUserDto = response.data.data;
    if (response.data.data) {
      itpUserDetail.name = `${response.data.data.first_name} ${response.data.data.last_name}`;
      setItpUserDetail(itpUserDetail);
    } else {
      setItpUserDetail(new ITPUserDto());
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");

  useEffect(() => {
    if (id) getITPUserById();
    return () => {
      setItpUserDetail(itpUserDetail);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Layout>
      <Container className="p-5">
        <ITPUserForm itpUserDetail={itpUserDetail} />
      </Container>
    </Layout>
  );
}
export default NewITPUser;
