import React, { useState } from 'react';
import SheduleAppointment from '../NewAppointment/SheduleAppointment';
import InmateInfoForm from '../NewAppointment/InmateInfoForm';
import ConfirmAppointmentForm from '../NewAppointment/ConfirmAppointmentForm';

const Steppers: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({
    appointmenttypeid: '',
    appointmentdatetime: '',
    inmatename: '',
    inmatedob: '',
    heldforanothercounty: false,
    countyid: 0,
    admincountyid: 0,
    medications: '',
    additionalinfo: '',
    created: new Date(),
    createdby: 0,
    inmatewillbeseenname: '',
    appointmenttypename: '',
    heldforaothercountyname:'',
    admincountyname: '',
    providername:'',
    providerId: 0,
    isHeldFor: false
  });

  const updateFormData = (data: any) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
  };

  const changeStep = (cur: number) => {
    setCurrent((c) => c + cur);
  };

  const newSteps = [
    {
      title: 'Schedule an Appointment',
      content: <SheduleAppointment changeStep={changeStep} updateFormData={updateFormData} formData={formData} />,
    },
    {
      title: 'Inmate Information',
      content: <InmateInfoForm changeStep={changeStep} updateFormData={updateFormData} formData={formData} />,
    },
    {
      title: 'Confirm Application',
      content: <ConfirmAppointmentForm changeStep={changeStep} formData={formData} />
    }
  ];

  return (
    <div>
      <div>{newSteps[current]?.content}</div>
    </div>
  );
};

export default Steppers;