/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CorrectionalFacilityDetailDto } from "../../models/correctionalFacilityDtos";
import { IOption } from "../../models/select-option";
import { phoneNumberFormat, zipCodeFormat } from "../Shared/Share";
import { UsaStates, UsaStatesEnum } from "../../constants/UsaStatesEmun";
import { CorrectionalFacilityServiceInternal } from "../../services/correctionFacilities.service";
import { AppRoutings } from "../../enums/app-routing";
import CustomTextField from "../FormElements/CommonTextField";
import { CustomPatternInput } from "../FormElements/CommonPatternInput";
import { CustomAutoComplete } from "../FormElements/CommonAutoComplete/CustomAutoComplete";
import CustomCheckbox from "../FormElements/CommonCheckBox";
import CustomTextArea from "../FormElements/CommonTextArea";

interface Props {
    correctionalFacilityDetail: CorrectionalFacilityDetailDto;
}

const CorrectionFacilityForm: FC<Props> = ({
    correctionalFacilityDetail,
}) => {
    const navigate = useNavigate();
    const [correctionalFacilityDetailContent, setCorrectionalFacilityDetailContent] = useState<CorrectionalFacilityDetailDto>(new CorrectionalFacilityDetailDto());
    const [stateOption, setStateOption] = useState<IOption[]>([]);

    const validationSchema = yup.object().shape({
        email: yup.string().email("Email not valid.").required("Email Address is Required"),
        officerName: yup.string().matches(/^[a-zA-Z]+ [a-zA-Z]+$/, 'Officer Name should contain both first name and last name').required('Officer Name is required'),
        name: yup.string().required("Correctional Facility Name is required"),
        address: yup.string().required("Address is required"),
        phoneNumber: yup
            .string()
            .required("Phone Number is required")
            .test("format", "Please enter the correct Phone Number", function (value) {
                const formatList = phoneNumberFormat.split("-");
                if (!formatList.length) return true;
                let isInValid = false;
                const tempList = value
                    .replaceAll("_", "")
                    .split("-");
                if (tempList.length !== formatList.length) isInValid = true;
                else {
                    for (let i = 0; i < tempList.length; i++) {
                        if (tempList[i].length !== formatList[i].length) {
                            isInValid = true;
                            break;
                        }
                    }
                }
                return !isInValid;
            }),
        city: yup.string().required('City is required'),
        state: yup.string().required('State is required'),
        zipCode: yup
            .string()
            .matches(zipCodeFormat, 'Invalid zip code')
            .required('Zip code is required'),
        ipList: yup
            .string()
            .test('ip-list', 'Invalid IP address list', function (value) {
                if (!value) return true; // Allow empty value if not required
                const ipAddresses = value.split(',').map(ip => ip.trim());
                // Check each IP address or range for validity
                return ipAddresses.every(ip =>
                    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip) ||
                    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[1-2][0-9]|3[0-2])$/.test(ip)
                );
            })
    });

    useEffect(() => {
        setStateOption(Object.keys(UsaStatesEnum).map((state) => ({
            label: state,
            value: UsaStatesEnum[state as UsaStates],
        })))
    }, []);

    const methods = useForm<CorrectionalFacilityDetailDto>({
        defaultValues: new CorrectionalFacilityDetailDto(),
        mode: "onChange",
        resolver: yupResolver(validationSchema)
    });

    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get("id");

    const handleSaveCorrectionalFacility = async (data: CorrectionalFacilityDetailDto) => {
        try {
            if (data.correctionalFacilityId === -1) {
                data.correctionalFacilityId = null as any;
                await CorrectionalFacilityServiceInternal.saveCorrectionalFacility(data);
                toast.success("Correctional Facility Saved successfully.");
            }
            else {
                await CorrectionalFacilityServiceInternal.updateCorrectionalFacility(data, id)
                toast.success("Correctional Facility Updated successfully.");
            }
            navigate(AppRoutings.CorrectionalFacilityGrid)
        }
        catch (error: any) {
            toast.error("Error while updating Correctional Facility detail, please try again!");
        }
    }

    useEffect(() => {
        if (correctionalFacilityDetail && correctionalFacilityDetail.correctionalFacilityId !== -1) {
            setCorrectionalFacilityDetailContent(correctionalFacilityDetail)
            methods.reset(correctionalFacilityDetail);
        } else {
            methods.reset(new CorrectionalFacilityDetailDto());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correctionalFacilityDetail]);

    return (
        <>
            <FormProvider {...methods}>
                <Grid className="d-flex justify-content-center" >
                    <Card style={{ width: '80%' }}>
                        <Card.Body>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        Correctional Facility Name :
                                    </Typography>
                                    <CustomTextField
                                        name="name"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        Email :
                                    </Typography>
                                    <CustomTextField
                                        name="email"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        Officer Name :
                                    </Typography>
                                    <CustomTextField
                                        name="officerName"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        Address :
                                    </Typography>
                                    <CustomTextField
                                        name="address"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        City :
                                    </Typography>
                                    <CustomTextField
                                        name="city"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        State :
                                    </Typography>
                                    <CustomAutoComplete
                                        name="state"
                                        options={stateOption}
                                        className="w-100"
                                    />
                                </Grid>                               
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        ZipCode :
                                    </Typography>
                                    <CustomTextField
                                        name="zipCode"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        Phone Number :
                                    </Typography>
                                    <CustomPatternInput
                                        name="phoneNumber"
                                        patternFormat={phoneNumberFormat.replace("_", "#")}
                                        className="w-100"                                      
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Typography variant="subtitle2" >
                                        IP White list :
                                    </Typography>
                                    <CustomTextArea
                                    name="ipList"
                                    fullWidth
                                    rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                                    <Typography variant="subtitle2" sx={{ marginTop:"7px", marginRight:"5px" }}>
                                        Allow To Specify Held For Facility:
                                    </Typography>
                                    <CustomCheckbox
                                        name="isheldFor"
                                        className=""
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "end" }}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        navigate(AppRoutings.CorrectionalFacilityGrid)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Box sx={{ marginLeft: "10px" }}>
                                    <Button
                                        variant="contained"
                                        onClick={methods.handleSubmit((formData) =>
                                            handleSaveCorrectionalFacility(formData)
                                        )}
                                    >
                                        {correctionalFacilityDetailContent.correctionalFacilityId !== -1 ? "Update" : "Save"}
                                    </Button>
                                </Box>
                            </Box>
                        </Card.Body>
                    </Card>
                </Grid>
            </FormProvider >
        </>
    );
}

export default CorrectionFacilityForm;