import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    IconButton,
    Button,
    InputLabel,
    Select,
    Grid,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TimeSlot } from "./WeekDay";

interface TimeSlotModelProps {
    open: boolean;
    onClose: () => void;
    items: string[];
    setItems: React.Dispatch<React.SetStateAction<string[]>>;
    onSelectedTimeSlotsChange: (timeSlots: string[]) => void;
    currentWeekday: keyof TimeSlot;
    selectedTimeSlots: TimeSlot;
}

const TimeSlotModel = ({
    open,
    onClose,
    items,
    setItems,
    currentWeekday,
    selectedTimeSlots,
    onSelectedTimeSlotsChange
}: TimeSlotModelProps) => {

    const [selectedStartTime, setSelectedStartTime] = useState('08:00 AM');
    const [selectedEndTime, setSelectedEndTime] = useState('10:00 AM');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const generateTimeSlots = () => {
        const timeSlots = [];
        let currentDate = new Date();
        currentDate.setHours(8, 0, 0, 0); // Set initial time to 8:00 AM

        while (currentDate.getHours() < 17) {
            const timeString = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            timeSlots.push({ value: timeString, label: timeString });
            currentDate.setTime(currentDate.getTime() + 30 * 60 * 1000); // Increment by 30 minutes
        }
        return timeSlots;
    };

    const compareTimes = (timeA: string, timeB: string): number => {
        const [hoursA, minutesA, periodA] = timeA.match(/(\d+):(\d+)\s?([APMapm]{2})?/)!.slice(1);
        const [hoursB, minutesB, periodB] = timeB.match(/(\d+):(\d+)\s?([APMapm]{2})?/)!.slice(1);

        const normalizedHoursA = periodA && periodA.toUpperCase() === 'PM' && parseInt(hoursA, 10) !== 12
            ? parseInt(hoursA, 10) + 12
            : parseInt(hoursA, 10);

        const normalizedHoursB = periodB && periodB.toUpperCase() === 'PM' && parseInt(hoursB, 10) !== 12
            ? parseInt(hoursB, 10) + 12
            : parseInt(hoursB, 10);

        if (normalizedHoursA !== normalizedHoursB) {
            return normalizedHoursA - normalizedHoursB;
        }

        return parseInt(minutesA, 10) - parseInt(minutesB, 10);
    };

    const timeSlots = generateTimeSlots();

    const handleTimeChange = (event: { target: { value: any; }; }) => {
        const selectedTime = event.target.value;
        setSelectedStartTime(selectedTime);
        setSelectedEndTime(selectedTime);
    };
    const resetTimeInputs = () => {
        setSelectedStartTime("08:00 AM");
        setSelectedEndTime("10:00AM");
    };

    const handleAddTimeSlot = () => {

        const timeSlot = `${selectedStartTime} - ${selectedEndTime}`;

        setErrorMessage(null);

        if (selectedStartTime === "08:00 AM" && selectedEndTime === "10:00AM") {
            setErrorMessage("Start time and end time cannot be the same!");
            return;
        }

        if (selectedStartTime === selectedEndTime) {
            setErrorMessage("Start time and end time cannot be the same!");
            return;
        }

        const parseTime = (timeString: any) => {
            const [hours, minutes, period] = timeString.match(/(\d+):(\d+)\s?([APMapm]{2})?/)!.slice(1);

            const normalizedHours = period && period.toUpperCase() === 'PM' && parseInt(hours, 10) !== 12
                ? parseInt(hours, 10) + 12
                : parseInt(hours, 10);

            return { hours: normalizedHours, minutes: parseInt(minutes, 10) };
        };

        const isOverlap = selectedTimeSlots[currentWeekday].some((existingSlot) => {
            const [existingStart, existingEnd] = existingSlot.split(" - ");
            const parseExistingStart = parseTime(existingStart);
            const parseExistingEnd = parseTime(existingEnd);

            const parseSelectedStart = parseTime(selectedStartTime);
            const parseSelectedEnd = parseTime(selectedEndTime);

            const isBeforeExistingSlot = (
                (parseSelectedEnd.hours < parseExistingStart.hours) ||
                (parseSelectedEnd.hours === parseExistingStart.hours && parseSelectedEnd.minutes <= parseExistingStart.minutes)
            );

            const isAfterExistingSlot = (
                (parseSelectedStart.hours > parseExistingEnd.hours) ||
                (parseSelectedStart.hours === parseExistingEnd.hours && parseSelectedStart.minutes >= parseExistingEnd.minutes)
            );

            return !(isBeforeExistingSlot || isAfterExistingSlot);
        });


        if (isOverlap) {
            setErrorMessage("Time slots already exist for given time!");
            return;
        }

        if (timeSlot.trim() !== "") {
            setItems([...items, timeSlot]);
        }
        onSelectedTimeSlotsChange([
            ...selectedTimeSlots[currentWeekday],
            timeSlot,
        ]);
        resetTimeInputs();
        onClose();
    };

    useEffect(() => {
        if (!open) {
            setErrorMessage(null);
        }
        resetTimeInputs();
    }, [open]);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Add Time Slot
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography>
                            <InputLabel htmlFor="begin-time">Begin Time</InputLabel>
                            <Select
                                native
                                fullWidth
                                inputProps={{
                                    name: 'begin-time',
                                    id: 'begin-time',
                                }}
                                value={selectedStartTime}
                                onChange={handleTimeChange}
                            >
                                {timeSlots.map((timeSlot) => (
                                    <option key={timeSlot.value} value={timeSlot.value}>
                                        {timeSlot.label}
                                    </option>
                                ))}
                            </Select>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            <InputLabel htmlFor="end-time">End Time</InputLabel>
                            <Select
                                native
                                fullWidth
                                inputProps={{
                                    name: 'end-time',
                                    id: 'end-time',
                                }}
                                value={selectedEndTime}
                                onChange={(event) => setSelectedEndTime(event.target.value)}
                            >
                                {/* {timeSlots.map((timeSlot) => (
                                    <option key={timeSlot.value} 
                                    value={timeSlot.value}>
                                        {timeSlot.label}
                                    </option>

                                ))} */}

                                {timeSlots.map((timeSlot) => {
                                    const isDisabled =
                                        selectedStartTime !== null &&
                                        compareTimes(timeSlot.value, selectedStartTime) < 0;

                                    return (
                                        <option
                                            key={timeSlot.value}
                                            value={timeSlot.value}
                                            disabled={isDisabled}
                                        >
                                            {timeSlot.label}
                                        </option>
                                    );
                                })}
                            </Select>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid mt={2}>
                    {errorMessage && (
                        <Typography color="error" variant="subtitle2">
                            {errorMessage}
                        </Typography>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleAddTimeSlot}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default TimeSlotModel;