import create from "zustand";
import { persist } from "zustand/middleware";

const userStore = create(
  persist(
    (set, get) => ({
      isLoginRequired: false,
      setisLoginRequired: (status: boolean) => {
        set({ isLoginRequired: status });
      },
      user: undefined,
      redirectUrlPath: undefined,
      getUser: () => {
        const pStore: any = get();
        return pStore.user;
      },
      setUser: (newUser: any) => {
        set({ user: newUser });
      },
      reset: () => {
        set({});
      },
    })
  )
);

export default userStore;