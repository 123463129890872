import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
	Checkbox,
	FormControlLabel,
	FormControl,
	FormHelperText,
} from "@mui/material";

import { CustomCheckboxProps } from "./CustomCheckbox.Props";
import { checkBoxCheckedIcon, checkboxDefaultIcon } from "../../../utils/icon";

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
	name = "",
	label,
	className="",
	isDisabled,
	isChecked = false, // Required to manage checkbox selection directly from parent component, so value prop from controller component won't be used
	handleChange,
}) => {
	const methods = useFormContext();

	return (
		<FormControl className={className}>
			<FormControlLabel
				control={
					<Controller
						name={name}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<Checkbox
								checked={isChecked || value || false}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									onChange(event);
									if (handleChange) {
										handleChange(event?.target.checked);
									}
								}}
								className="custom-checkbox"
								checkedIcon={checkBoxCheckedIcon}
								icon={checkboxDefaultIcon}
								disabled={isDisabled}
								style={{display: "flex"}}
							/>
						)}
						control={methods.control}
					/>
				}
				label={label}
				disabled={isDisabled}
				style={{padding:"0px", display: "flex"}}
			/>
			{methods.formState.errors[name] && (
				<FormHelperText error>
					{(methods.formState.errors[name]?.message as React.ReactNode) || 'Error'}
				</FormHelperText>
			)}
		</FormControl>
	);
};

export default CustomCheckbox;
