import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { Card } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import WeekdayDropdown, { TimeSlot } from "../Shared/WeekDay";
import MultiSelectDropDown from "../Shared/MultiSelectDropDown";
import TimeSlotModel from "../Shared/TimeSlotPopUpModel";
import MultiSelectDayOffDropDown from "../Shared/MultiSelectDayOffDropDown";
import TimeDateSlotModel from "../Shared/TimeDateSlotPopUpModel";
import CustomDatePicker from "../Shared/CustomDatePicker";
import { CorrectionalFacilityServiceInternal } from "../../services/correctionFacilities.service";
import { toast } from "react-toastify";
import { ActionMeta, MultiValue } from "react-select";
import FullPageLoader from "../Shared/FullPageLoader";
import { UsaStates, UsaStatesEnum } from '../../constants/UsaStatesEmun';
import { ProviderServiceInternal } from "../../services/provider.service";

const ProviderManagementForm = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState<string[]>([]);
    const [currentWeekday, setCurrentWeekday] = useState<keyof TimeSlot>('Monday');
    const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlot>({
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
    });
    const [open, setOpen] = useState(false);
    const [DayOffopen, setDayOffOpen] = useState(false);
    const [dayOffTimeSlots, setDayOffTimeSlots] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState<any>([]);
    const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);
    const [selectedStates, setSelectedStates] = useState<any>(null);
    const [selectedFacilities, setSelectedFacilities] = useState<any>(null);
    const [existingTimeSlots, setExistingTimeSlots] = useState<string[]>([]);
    const [facilityOptions, setFacilityOptions] = useState<{ value: string; label: string }[]>([]);


    const handleAddTimeSlot = (selectedDate: string, selectedStartTime: string, selectedEndTime: string) => {
        const formattedDate = selectedDate.split('T')[0].replace(/-/g, '/');
        const newTimeSlot = `${formattedDate} ${selectedStartTime}-${selectedEndTime}`;
        setDayOffTimeSlots((prevTimeSlots) => [...prevTimeSlots, newTimeSlot]);
        setExistingTimeSlots([...existingTimeSlots, newTimeSlot]);
        handleDayOffClose();
    };

    const handleRemoveTimeSlot = (indexToRemove: number) => {
        setDayOffTimeSlots((prevTimeSlots) =>
            prevTimeSlots.filter((_, index) => index !== indexToRemove)
        );
        setExistingTimeSlots((prevTimeSlots) =>
            prevTimeSlots.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleDayOffClose = () => {
        setDayOffOpen(false);
    };
    const handleDayOffOpen = () => {
        setDayOffOpen(true);
    };
    const handleWeekdayCheckboxChange = (weekday: keyof TimeSlot) => {
        setCurrentWeekday(weekday);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = (weekday: keyof TimeSlot) => {
        setCurrentWeekday(weekday);
        setOpen(true);
    };

    const handleDateChange = (date: any) => {
        setSelectedDate(Array.isArray(date) ? date : [date]);
    };

    const handleFacilityChange = (
        newValue: MultiValue<{ value: string; label: string }>,
        actionMeta: ActionMeta<{ value: string; label: string }>
    ) => {
        if (newValue) {
            const selectedValues = newValue.map(option => option);
            setSelectedFacilities(selectedValues);
        } else {
            setSelectedFacilities([]);
        }
    };

    const handleStatesChange = (
        newValue: MultiValue<{ value: string; label: string }>,
        actionMeta: ActionMeta<{ value: string; label: string }>
    ) => {
        if (newValue) {
            const selectedValues = newValue.map(option => option);
            setSelectedStates(selectedValues);
        } else {
            setSelectedStates([]);
        }
    };


    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            provideremail: "",
            providername: "",
            correctionalFacilities: [],
            states: [],
            sessionLink: "",
        },
        resolver: yupResolver(
            yup.object().shape({
                provideremail: yup
                    .string()
                    .email("Email not valid.")
                    .required("Provider email is required"),
                providername: yup.string().required("Provider name is required"),
                correctionalFacilities: yup
                    .array()
                    .min(1, "correctional facility must be selected"),
                states: yup
                .array()
                .min(1, "state must be selected"),
                sessionLink: yup.string().required("SessionLink is required"),
            })
        ),
    });

    const handleSelectedTimeSlotsChange = (weekday: keyof TimeSlot, timeSlots: Array<string>) => {
        setSelectedTimeSlots(prevState => ({
            ...prevState,
            [weekday]: timeSlots,
        }));
    };

    const onCancelButtonClick = () => {
        window.location.href = "provider-management";
    }

    const onSubmit = async (data: any) => {
        const holidays: number[] = selectedDate;
        const holidayDates = holidays.map(timestamp => new Date(timestamp));
        const formattedHolidayDates = holidayDates.map(date => date.toLocaleDateString("en-US"));

        const formData = {
            ...data,
            WeeklyAvailability: selectedTimeSlots,
            DayAndTimeOff: dayOffTimeSlots,
            CorrectionalFacilities: selectedFacilities,
            Holidays: formattedHolidayDates,
        };
        const urlSearchParams = new URLSearchParams(window.location.search);
        const id = urlSearchParams.get("id");
        const isUpdate = Number(id) > 0;
        
        if (!isUpdate) {
            const response = await ProviderServiceInternal.saveprovider(formData);
            if (response?.data.isSuccess) {
                window.location.href = "/provider-management";
                toast.success(response?.data.message);
            }
            else{
                toast.error(response?.data.message);
            }
        }
        else {
            formData.UserId = id;
            const response = await ProviderServiceInternal.updateprovider(formData);
            if (response?.data.isSuccess) {
                window.location.href = "/provider-management";
                toast.success(response?.data.message);
            }
            else{
                toast.error(response?.data.message);
            }
        }
    }

    useEffect(() => {
        const fetchFacilities = async () => {
            try {
                const response = await CorrectionalFacilityServiceInternal.getCorrectionalFacilitiesForProvider();
                if (response?.data.isSuccess && response.data.data) {

                    const options = response.data.data.map((facility: { id: number; name: string }) => ({
                        value: facility.id.toString(),
                        label: facility.name
                    }));
                    setFacilityOptions(options);
                } else {
                    console.error("Error fetching correctional facilities");
                }

            } catch (error) {
                console.error("An error occurred while fetching correctional facilities", error);
            }
        };
        fetchFacilities();
    }, []);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get("id");

    const fetchData = async () => {
        try {
            const response = await ProviderServiceInternal.getproviderbyuserid(Number(id));
            if (response?.data.isSuccess) {
                const apiDataList = response.data.data;
                reset({
                    provideremail: apiDataList[0].providerEmail,
                    providername: apiDataList[0].providerName,
                    sessionLink: apiDataList[0].sessionLink,
                    correctionalFacilities: apiDataList[0].correctionalFacilitie.split(",").map((value: string) => ({
                        value: value.trim(),
                        label: value.trim()
                    })),
                    states: apiDataList[0].stateNames.split(",").map((value: string) => ({
                        value: value.trim(),
                        label: value.trim()
                    }))
                });
                const correctionalFacilitiesString = apiDataList[0].correctionalFacilitie || "";
                const correctionalFacilitiesArray = correctionalFacilitiesString.split(",").map((value: string) => value.trim());

                const selectedFacilities = correctionalFacilitiesArray.map((value: string) => {
                    const facilityOption = facilityOptions.find((option: any) => option.value === value);
                    return facilityOption || null;
                }).filter(Boolean);
                setSelectedFacilities(selectedFacilities);

                const stateNamesString = apiDataList[0].stateNames || "";
                const statesArray = stateNamesString.split(",").map((value: string) => value.trim());

                const selectedStates = statesArray.map((value: string) => {
                    const stateOption = statesOptions.find((option: any) => option.value === value);
                    return stateOption || null;
                }).filter(Boolean);
                setSelectedStates(selectedStates);

                const holidayDates = (apiDataList[0].holidays as string[])
                    .map((dateString: string) => new Date(dateString))
                    .filter(date => !isNaN(date.getTime()));

                setSelectedDate(holidayDates);

                const dayAndTimeOff = apiDataList[0].dayAndTimeOff || [];
                setDayOffTimeSlots(dayAndTimeOff);
                const updatedSelectedTimeSlots: TimeSlot = {
                    Sunday: [],
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                };
                apiDataList.forEach((apiData: { weeklyAvailability: { [x: string]: never[]; }; }) => {
                    Object.keys(apiData.weeklyAvailability).forEach(weekday => {
                        if (weekday in updatedSelectedTimeSlots) {
                            updatedSelectedTimeSlots[weekday as keyof TimeSlot] = apiData.weeklyAvailability[weekday] || [];
                        }
                    });
                });
                setSelectedTimeSlots(updatedSelectedTimeSlots);

                const updatedSelectedWeekdays: string[] = [];
                apiDataList.forEach((apiData: { weeklyAvailability: { [x: string]: never[]; }; }) => {
                    Object.keys(apiData.weeklyAvailability).forEach(weekday => {
                        if (apiData.weeklyAvailability[weekday].length > 0) {
                            updatedSelectedWeekdays.push(weekday);
                        }
                    });
                });

                setSelectedWeekdays(updatedSelectedWeekdays);
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const statesOptions = Object.keys(UsaStatesEnum).map((state) => ({
        label: state,
        value: UsaStatesEnum[state as UsaStates],
    }));

    useEffect(() => {
        fetchData();
    }, [reset, facilityOptions]);

    return (
        <>
            {isLoading && <FullPageLoader />}
            <form className="add-patient-form mt-3" onSubmit={handleSubmit(onSubmit, (err) => {
            })}>
                <div className="d-flex justify-content-center">
                    <Card style={{ width: '100%' }}>
                        <Card.Body>
                            <div className="referral-form">
                                <div className="row">
                                    <div className="col-md-5 mt-4">
                                        <div>
                                        <Typography variant="subtitle2" sx={{color:"gray"}}>
                                            Email Address:
                                        </Typography>
                                            <Controller
                                                name="provideremail"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"
                                                        className="m-auto"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                            {errors.provideremail && (
                                                <p className="text-red-600 text-sm">
                                                    {errors.provideremail.message}
                                                </p>
                                            )}
                                        </div>
                                        <div>
                                        <Typography variant="subtitle2" sx={{ marginTop: 2, color:"gray"}}>
                                            Provider Name:
                                        </Typography>
                                            <Controller
                                                name="providername"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="text"
                                                        className="m-auto"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                            {errors.providername && (
                                                <p className="text-red-600 text-sm">
                                                    {errors.providername.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <div className="form-label w-full md:w-full">
                                            <MultiSelectDropDown
                                                label="Associated Correctional Facilities"
                                                options={facilityOptions}
                                                onChange={handleFacilityChange}
                                                placeholder=""
                                                selectedValues={selectedFacilities}
                                                error={errors.correctionalFacilities?.message}
                                                name="correctionalFacilities"
                                                control={control}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <div className="form-label">
                                            <MultiSelectDropDown
                                                label="Associated States"
                                                options={statesOptions}
                                                onChange={handleStatesChange}                                                
                                                placeholder=""
                                                selectedValues={selectedStates}
                                                error={errors.states?.message}
                                                name="states"
                                                control={control}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <WeekdayDropdown
                                    label="Weekly Availibility:"
                                    handleOpen={handleOpen}
                                    selectedTimeSlots={selectedTimeSlots}
                                    onSelectedTimeSlotsChange={handleSelectedTimeSlotsChange}
                                    onWeekdayCheckboxChange={handleWeekdayCheckboxChange}
                                    selectedWeekdays={selectedWeekdays}
                                    setSelectedWeekdays={setSelectedWeekdays}
                                />

                            </div>
                            <div className="mt-5 row md:flex-nowrap">
                                <div className="col-md-3 col-12">
                                    <div className="">
                                        <CustomDatePicker
                                            label="Holidays:"
                                            onChange={handleDateChange}
                                            setSelectedDate={setSelectedDate}
                                            selectedDate={selectedDate}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-7 col-12">
                                    <MultiSelectDayOffDropDown
                                        label="Days/Time Off:"
                                        handleOpen={handleDayOffOpen}
                                        timeSlots={dayOffTimeSlots}
                                        onRemoveTimeSlot={handleRemoveTimeSlot}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7 mt-4">
                                    <div>
                                        <Typography variant="subtitle2" sx={{ marginTop: 2, color:"gray"}}>
                                            Session Link:
                                        </Typography>
                                        <Controller
                                            name="sessionLink"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="outlined"
                                                    margin="normal"
                                                    type="text"
                                                    className="m-auto"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                        {errors.sessionLink && (
                                            <p className="text-red-600 text-sm">
                                                {errors.sessionLink.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-10 text-end mt-5 me-5">
                                    <Button type='button' variant="contained" onClick={onCancelButtonClick} style={{ minWidth: "120px" }}>Cancel</Button>{" "}
                                    <Button type='submit' variant="contained" style={{ minWidth: "120px" }}>{id ? "Update" : "Save"}</Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <TimeSlotModel
                    open={open}
                    onClose={handleClose}
                    items={items}
                    setItems={setItems}
                    selectedTimeSlots={selectedTimeSlots}
                    currentWeekday={currentWeekday}
                    onSelectedTimeSlotsChange={(timeSlots: string[]) => handleSelectedTimeSlotsChange(currentWeekday, timeSlots)}
                />

                <TimeDateSlotModel
                    open={DayOffopen}
                    onClose={handleDayOffClose}
                    onAddTimeSlot={handleAddTimeSlot}
                    existingTimeSlots={existingTimeSlots}
                />
            </form >
        </>
    )
}
export default ProviderManagementForm;