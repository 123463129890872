import { Button, Chip, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface HolidayDropdownProps {
  label: string;
  handleOpen: () => void;
  timeSlots: string[];
  onRemoveTimeSlot: (indexToRemove: number) => void;
}

const MultiSelectDayOffDropDown: React.FC<HolidayDropdownProps> = ({ label, handleOpen, timeSlots, onRemoveTimeSlot }) => {

  const handleRemoveTimeSlot = (indexToRemove: number) => {
    onRemoveTimeSlot(indexToRemove);
  };

  return (
    <div className="row md:flex-nowrap">
      <div className='col-md-12'>
      <Typography variant="subtitle2" className='mt-3' sx={{ marginTop: 2, color:"gray", marginLeft: 1, marginBottom: 1.2}}>
          {label}
      </Typography>
        <div className='' style={{ border: "1px solid #c4c4c4", height: "113px", overflow: "auto" }}>
          {timeSlots.map((slot, index) => (
            slot && (
              <Chip className='ms-1 mt-1 mb-1' label={slot} onDelete={() => handleRemoveTimeSlot(index)} />
            )
          ))}
        </div>
      </div>
      <div className='col-md-2 d-flex md:mt-5 mt-2 align-items-center md:ms-3'>
        <Button variant="contained" onClick={handleOpen}>
          +
        </Button>
      </div>
    </div>
  );
};

export default MultiSelectDayOffDropDown;