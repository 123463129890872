import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CustomCard from "../Shared/Card";
import LoginHeader from "../Login/LoginHeader";
import { useParams } from "react-router-dom";
import { forgotpassword } from "../../api/endpoints";
import { toast } from "react-toastify";

export default function ForgottPassword() {

    const { token } = useParams();
    const {
        control, // Add control
        handleSubmit,
        formState: { errors }, // Add errors
    } = useForm({
        defaultValues: {
            email: ""
        },
        resolver: yupResolver(yup.object().shape({
            email: yup
                .string()
                .email("Email must be a valid email")
                .required("Email is Required"),
        })),
    });

    const onSubmit = async (data: any) => {
        var response = await forgotpassword(data);
        if (response?.data.isSuccess) {
            window.location.href = "/login";
        }
        else if(response?.data.isSuccess == false)
        {
            toast.error(response?.data.message);
        }
    };

    return (
        <>
            <div className="w-full flex justify-center mt-3">
                <CustomCard isFitContent={true}>
                    <Grid container className="justify-center">
                        <Grid item xs={12} md={10}>
                            <div className="w-full">
                                <form className="mt-4 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                    <LoginHeader />
                                    <div className="-space-y-px">
                                        <div className="my-2">
                                            <label htmlFor="email-address" className="sr-only">
                                                Email address
                                            </label>
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        id="email-address"
                                                        type="email"
                                                        className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                                        placeholder="Email address"
                                                    />
                                                )}
                                            />
                                            {errors.email && (
                                                <p className="text-red-600 text-sm">{errors.email.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-10 mb-4"
                                    >
                                        Reset
                                    </button>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </CustomCard>
            </div>
        </>
    );
}