import React, { useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Chip, Typography } from '@mui/material';

interface CustomDatePickerProps {
    onChange: (date: any) => void;
    setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
    selectedDate: any; // Add this line
    label: string;
}
const currentDate = new Date();

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ onChange, setSelectedDate, selectedDate, label }) => {

    const handleChange = (date: any) => {
        const sortedDates = Array.isArray(date)
            ? [...date].sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
            : date;
        setSelectedDate(sortedDates as any);
        onChange(sortedDates);
    };

    const handleRemoveDate = (dateToRemove: any) => {
        setSelectedDate((prevSelectedDate: any) => {
            if (Array.isArray(prevSelectedDate)) {
                return prevSelectedDate.filter((date) => date !== dateToRemove);
            } else {
                return null;
            }
        });
    };

    return (
        <div className="">
            <div className="d-flex">
                <div>
                <Typography variant="subtitle2" sx={{ marginTop: 2, color:"gray" }}>
                    {label}
               </Typography>
                </div>
                <div className="mt-3 my-2" style={{ marginLeft: "auto" }}>
                    <DatePicker
                        onChange={handleChange}
                        format="MM/DD/YYYY"
                        multiple
                        value={selectedDate}
                        minDate={currentDate}
                        render={(value, openCalendar) => {
                            return (
                                <div onClick={openCalendar} style={{ cursor: "pointer" }}>
                                    <CalendarMonthIcon />
                                </div>
                            );
                        }}
                    />
                </div>
            </div>

            <div
                style={{
                    height: "110px",
                    width: "100%",
                    border: "1px solid #c4c4c4",
                    overflow: "auto",
                }}
            >
                {selectedDate &&
                    (Array.isArray(selectedDate) ? (
                        selectedDate.map((date: any) => (
                            <Chip
                                className='mt-1 ms-1 mb-1'
                                key={date}
                                label={new Date(date).toLocaleDateString("en-US")}
                                onDelete={() => handleRemoveDate(date)}
                                style={{ marginBottom: "5px" }}
                            />
                        ))
                    ) : (
                        <Chip
                            className='mt-1 ms-1 mb-1'
                            label={new Date(selectedDate).toLocaleDateString("en-US")}
                            onDelete={() => handleRemoveDate(selectedDate)}
                            style={{ marginBottom: "5px" }}
                        />
                    ))}
            </div>
        </div>
    );
};

export default CustomDatePicker;
