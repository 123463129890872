import axios, { AxiosRequestConfig } from 'axios';
import instance from './http.common';
import Cookies from 'js-cookie';

export default function SetupInterceptors() {
  //const authCtx = useContext(AuthContext);

  // Request interceptor
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      // Add any headers or perform actions before the request is sent
      //const token = authCtx.token();
      const token = Cookies.get('access-token');
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Modify the response data or handle common response logic
      return response;
    },
    (error) => {
      // Handle errors globally
      return Promise.reject(error);
    }
  );
}

function useContext(AuthContext: any) {
  throw new Error('Function not implemented.');
}

