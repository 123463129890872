import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select, { Props as ReactSelectProps } from 'react-select';
import makeAnimated from 'react-select/animated';

interface Option {
  value: string;
  label: string;
}

interface MultiSelectDropDownProps {
  label: string;
  options: Option[];
  onChange?: ReactSelectProps<Option, true>['onChange'];
  placeholder?: string;
  selectedValues?: Option[];
  error?: string | null;
  name: string
  control: any
}

const animatedComponents = makeAnimated();

const MultiSelectDropDown: React.FC<MultiSelectDropDownProps> = ({ label, options, onChange, placeholder, selectedValues, error, name, control }) => {

  const [selectedOptions, setSelectedOptions] = useState<Option[] | undefined>(selectedValues);

  const handleSelectChange: ReactSelectProps<Option, true>['onChange'] = (selectedValues, actionMeta) => {
    setSelectedOptions(selectedValues as Option[]);
    if (onChange) {
      onChange(selectedValues, actionMeta);
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      height: '113px',
      overflow: 'auto',
      borderColor: error ? 'red' : provided.borderColor,
      //borderColor: isValid ? provided.borderColor : 'red',
    }),
  };

  useEffect(() => {
    setSelectedOptions(selectedValues);
  }, [selectedValues]);

  return (
    <div className=''>
       <Typography variant="subtitle2" sx={{ marginTop: 2 , color:"gray"}}>
          {label}
       </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={selectedValues} // set default value if needed
        render={({ field }) => (
          <Select
            className=''
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={options}
            value={selectedOptions}
            onChange={(selectedValues, actionMeta) => {
              handleSelectChange(selectedValues, actionMeta);
              field.onChange(selectedValues);
            }}
            placeholder={placeholder || 'Select options...'}
            styles={customStyles}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        )}
      />
      {error && <p className='text-red-600 text-sm'>{error}</p>}
    </div>
  );
};

export default MultiSelectDropDown;