import AppRoutes from './components/Routes';
import './App.css';
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "./store/auth-context";
import { AppRoutings } from "./enums/app-routing";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SetupInterceptors from './api/apiconfig';

function App() {
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const isUserLoggedIn = authCtx.isLoggedIn;
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.toLowerCase().startsWith("/patient-files")) {
      navigate(`/patient-files`);
    }
    else if (!isUserLoggedIn) {
      if (location.pathname.toLowerCase().startsWith("/reset-password")) {
        const token = location.pathname.split("/")[2];
        navigate(`/reset-password/${token}`);
      }
      else if (["/itp-login"].includes(location.pathname.toLowerCase())) {
        navigate("/itp-login");
      }
      else {
        navigate("/login");
      }
    }
  }, []);

  SetupInterceptors();
  return (
    <>
      <AppRoutes />
      <ToastContainer />      
    </>
  );
}

export default App;
