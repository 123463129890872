import { IconButton } from "@mui/material";
import SearchForm from "../DashBoard/DashboardSearchForm";
import SearchIcon from '@mui/icons-material/Search';
import ProviderTable from "./ProviderTable";
import { useEffect, useState } from "react";
import { AppointmentServiceInternal } from "../../services/appointment.service";
import PaginationControl from "../Layout/Pagination";
import "../Layout/Pagination/index.css";
import FullPageLoader from "../Shared/FullPageLoader";
import useStore from "../../store/store";
import { RECORDS_PER_PAGE } from '../../constants/index';
import { nextWeek, today } from "../Shared/Share";
import { toast } from "react-toastify";


const recordsPerPage = RECORDS_PER_PAGE;
const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);  
    return debouncedValue;
  };

function ProviderDashboard() {
 
    const user: any = useStore();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [inmateDob, setInmatedob] = useState<Date | null>(null);
    const [stateSearch, setStateSearch] = useState("");
    const debouncedSearchTerm = useDebounce(search, 300);
    const debouncedStateSearchTerm = useDebounce(stateSearch, 300);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const paginatedData = data?.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleStartDateChange = (date: string) => {        
        const formateDate = new Date(date);
        setStartDate(formateDate);
    };
    
    const handleEndDateChange = (dateString: string) => {        
        const date = new Date(dateString);
        setEndDate(date);
    };

    const fetchDataFromApi = async (isReset:boolean) => {
        try 
        {
            setIsLoading(true);
            const today = new Date();
            const nextWeek = new Date(today);
            nextWeek.setDate(nextWeek.getDate() + 7);
            let response;  
                
            if(isReset)
            {     
                response = await AppointmentServiceInternal.getappointment(today,nextWeek,"",null,"");               
            } 
            else
            {
                response = await AppointmentServiceInternal.getappointment(startDate ?? today, endDate ?? nextWeek, search ?? "", inmateDob ?? null, stateSearch ?? "");
            }
            if (response?.data.isSuccess) {
                setData(response.data.data);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data from API:', error);
        }
    }

    const getFullName = () => {
        const firstName = user.getUser() && user.getUser().first_name ? user.getUser().first_name : '';
        const lastName = user.getUser() && user.getUser().last_name ? user.getUser().last_name : '';
        return `${firstName} ${lastName}`;
    };

    const handleSearchChange = (search : string) => {
        setSearch(search);
    }; 

     const handleInmateDobChange = (inmateDob: string) => {        
        const formateDate = new Date(inmateDob);
        setInmatedob(formateDate);
    };

      const handleSearchStateChange = (searchState : string) => {
        setStateSearch(searchState);
    }; 

    const handleReset = () => {        
        setSearch("");        
        setStartDate(today);
        setEndDate(nextWeek);
        setInmatedob(null);
        setStateSearch("");
        fetchDataFromApi(true);
    }

    useEffect(() => {
        fetchDataFromApi(false);
        setCurrentPage(1);
      }, [debouncedSearchTerm, debouncedStateSearchTerm]);

    useEffect(() => {        
        fetchDataFromApi(false);
        setCurrentPage(1);
    }, [ startDate, endDate, inmateDob]);

    useEffect(() => {
        const newStartIndex = (currentPage - 1) * recordsPerPage;
        const newEndIndex = newStartIndex + recordsPerPage;
        setStartIndex(newStartIndex);
        setEndIndex(Math.min(newEndIndex, data.length));
    }, [currentPage, data, recordsPerPage]);
   
    const handleDeleteAppointment = async (userId: number) => {
        const response = await AppointmentServiceInternal.deleteAppointment(userId);
        if (response?.data.isSuccess) {
            fetchDataFromApi(false);
            toast.success(response?.data.message)
        }
        else{
            toast.error(response?.data.message)
        }
    };
    return (
        <div className="patient-dashboard">
            <div className="flex xs:block w-full dashboard-filter" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h1 className="text-3xl pb-4 mr-5">Provider Dashboard</h1>
                <div style={{fontSize:"larger"}}>
                    <span><strong>Provider Name :</strong></span>
                    <span> {getFullName()} </span>
                </div>
            </div>

            <div className="flex relative">
                <div className="justify-between flex">
                    <span>
                        <IconButton
                            aria-label="search"
                            size="large"
                            className="p-2"
                            disabled
                        >
                            <SearchIcon fontSize="inherit" />
                        </IconButton>
                    </span>                    
                    <SearchForm                     
                     onSearch = {handleSearchChange}
                     onStartDateChange={handleStartDateChange}
                     onEndDateChange={handleEndDateChange}
                     onInmateDobChange={handleInmateDobChange}
                     onSearchStateChange={handleSearchStateChange}
                     onHandleReset={handleReset}                     
                     />
                </div>
            </div>
            {isLoading && <FullPageLoader />}
            <>
                <div className="mt-5 overflow-auto">
                    <ProviderTable
                        data={paginatedData ?? []}
                        onDeleteAppointment={handleDeleteAppointment}
                    />
                </div>
                {paginatedData?.length > 0 && data && (
                    <PaginationControl
                        totalRecords={data?.length}
                        recordsPerPage={recordsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                )}
            </>
        </div>
    );
}
export default ProviderDashboard;