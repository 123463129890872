import instance from "../api/http.common";

const _rootUrl: string = process.env.REACT_APP_BASE_URL ?? "";
const COMMON_URL: string = _rootUrl + "/Appointment";

class AppointmentService {
    appointmenttype = async () => {
        return await instance.get(COMMON_URL + "/AppointmentType");
    }

    county = async () => {
        return await instance.get(COMMON_URL + "/County");
    }

    saveappointment = async (data: any) => {
        return await instance.post(COMMON_URL + "/SaveAppointment", data);
    };

    getappointment = async (startDate: Date | null, endDate: Date | null, search: string | null, inmateDob: Date | null, stateSearch: string | null) => {
        const params = {
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            search: search || null,
            inmateDob: inmateDob ? inmateDob : null,
            stateSearch: stateSearch || null
        };
        return await instance.get(COMMON_URL + "/GetAllAppointment", { params });
    };

    deleteAppointment = async (Id: number) => {
        return await instance.delete(COMMON_URL + "/DeleteAppointment", { params: { appointmentId: Id } });
    }

    saveAppointmentType = async (data: any) => {
        return await instance.post(COMMON_URL + "/SaveAppointmentType", data);
    };

    deleteAppointmentType = async (appointmentTypeId: number) => {
        return await instance.delete(COMMON_URL + "/DeleteAppointmentType", { params: { appointmentTypeId: appointmentTypeId } })
    };
}
export const AppointmentServiceInternal = new AppointmentService();