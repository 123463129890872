import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationModal from './ConfirmationModal';
import { BUTTONS } from '../../constants';

interface UserThreeDotsProps {
    onDeleteClick: (userId: number) => void;
    userId: number;
}

const KanbanMenuDashboard: React.FC<UserThreeDotsProps> = ({ onDeleteClick, userId }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openModel, setOpenModel] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        handleClose();
        setSelectedRowId(userId); // Assuming you want to delete the current user
        setOpenModel(true);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleDeleteClick}>
                    Cancel
                </MenuItem>
            </Menu>

            <ConfirmationModal
                open={openModel}
                title={"Are you sure to cancel this appointment?"}
                onConfirm={() => {
                    setOpenModel(false);
                    if (onDeleteClick && selectedRowId !== null) {
                        onDeleteClick(selectedRowId);
                    }
                }}
                onClose={() => {
                    setOpenModel(false);
                }}
                confirmText={BUTTONS.YES}
                cancelText={BUTTONS.NO}
            />
        </>
    )
}

export default KanbanMenuDashboard