import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/index.css';
import { AuthContextProvider } from './store/auth-context';

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
        <App />
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

reportWebVitals();