import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CustomCard from "../Shared/Card";
import LoginHeader from "../Login/LoginHeader";
import { resetpassword } from "../../api/endpoints";
import { useParams } from "react-router-dom";
import { SnackbarProvider, showSnackbar } from "../../utils/snackbar";
import { toast } from "react-toastify";

export default function ResetPassword() {
    const { token } = useParams();
    const {
        control, // Add control
        handleSubmit,
        formState: { errors }, // Add errors
    } = useForm({
        defaultValues: {
            newpassword: "",
            confirmpassword: ""
        },
        resolver: yupResolver(yup.object().shape({
            newpassword: yup
                .string()
                .required('New Password is required')
                .min(8, 'Password must be at least 8 characters long')
                .matches(
                    /^(?=.*[!@#$%^&*])/,
                    'Password must be 1 special character'
                )
                .matches(
                    /^(?=.*[0-9])/,
                    'Password must be 1 number value'
                )
                .matches(
                    /^(?=.*[A-Z])/,
                    'Password must be 1 uppercase letter'
                )
                .matches(
                    /^(?=.*[a-z])/,
                    'Password must be 1 lowercase letter'
                ),
                confirmpassword: yup
                .string()
                .oneOf([yup.ref('newpassword')], 'Passwords must match')
                .required('Confirm Password is required'),
        })),
    });

    const onSubmit = async (data: any) => {
      
        const response = await resetpassword({ ...data, token });
        if (response?.data.isSuccess) {
            window.location.href = "/login";
        }
        else if(response?.data.isSuccess == false){
            toast.error(response?.data.message);
        }
    };

    return (
        <>
            <div className="w-full flex justify-center mt-3">
                <CustomCard isFitContent={true}>
                    <Grid container className="justify-center">
                        <Grid item xs={12} md={10}>
                            <div className="w-full">
                                <form className="mt-4 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                    <LoginHeader />
                                    <div className="-space-y-px">
                                        <div className="my-2">
                                            <label htmlFor="newpassword" className="sr-only">
                                                NewPassword
                                            </label>
                                            <Controller
                                                name="newpassword"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        id="newpassword"
                                                        type="password"
                                                        className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                                        placeholder="New Password"
                                                    />
                                                )}
                                            />
                                            {errors.newpassword && (
                                                <p className="text-red-600 text-sm">{errors.newpassword.message}</p>
                                            )}
                                        </div>
                                        <div className="my-2">
                                            <label htmlFor="confirmpassword" className="sr-only">
                                                Confirm Password
                                            </label>
                                            <Controller
                                                name="confirmpassword"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        id="confirmpassword"
                                                        type="password"
                                                        className="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                                                        placeholder="Confirm Password"
                                                    />
                                                )}
                                            />
                                            {errors.confirmpassword && (
                                                <p className="text-red-600 text-sm">{errors.confirmpassword.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-10 mb-4"
                                    >
                                        Reset Password
                                    </button>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </CustomCard>
            </div>
        </>
    );
}