import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogTitle, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import NoRecordFoundComponent from "../Shared/NoRecords";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUploadModel from "../Shared/FileUploadModel";
import { number } from "yup";
import { FileUploadServiceInternal } from "../../services/fileUpload.service";
import { toast } from 'react-toastify';
import { ConvertDate, GetTime } from "../../common/commonFunction";
import { ProviderServiceInternal } from "../../services/provider.service";
import { handleJoinMeeting, isMeetingButtonEnabled } from "../Shared/Share";

interface PatientData {
    appointmentid: number;
    inmatename: string;
    provider: string;
    appointmenttypeid: number;
    type: string;
    status: string;
    inmatedob: Date;
    medications: string,
    additionalinfo: string,
    admincountyid: number,
    appointmentTypeName: string,
    inmatewillbeseenname: string,
    appointmentdatetime: any,
    facilityName: string,
    createdby: number,
    providerId: number
}
interface PatientTableProps {
    data: PatientData[];
}

const ITPTable = ({ data }: PatientTableProps) => {
    const [expandedRow, setExpandedRow] = useState<number | null>(null);
    const [open, setOpen] = React.useState(false);
    const [userId, setUserId] = useState<number>();
    const [appointmentId, setAppointmentId] = useState<number>(0);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = (userId: number, appointmentId: number) => {
        setOpen(true);
        setUserId(userId);
        setAppointmentId(appointmentId); // Corrected the variable name here
    }

    const handleExpandRow = (rowId: number) => {
        if (expandedRow === rowId) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowId);
        }
    };

    const appointmentdatetime = (date: any) => {
        const originalDate = new Date(date);
        const formattedDate = `${(originalDate.getMonth() + 1).toString().padStart(2, '0')}/${originalDate.getDate().toString().padStart(2, '0')}/${originalDate.getFullYear()}`;
        const formattedTime = `${originalDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        return {
            date: formattedDate,
            time: formattedTime
        };
        // const originalDate = new Date(date);
        // return {
        //     date: ConvertDate(originalDate),
        //     time: GetTime(originalDate)
        // };
    }

    const handleFileUpload = async (file: File) => {
        
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userid', Number(userId).toString());
        formData.append('appointmentid', Number(appointmentId).toString());
        const response = await FileUploadServiceInternal.uploadFile(formData);
        if (response?.data.isSuccess) {
            toast.success(response?.data.message);
            handleClose();
        }
        else {
            toast.error(response?.data.message);
        }
    };

    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow
                        style={{
                            color: "#A2A8BA",
                            backgroundColor: "#F6F7FB",
                        }}
                    >
                        <TableCell className="first-cell">Inmate Name</TableCell>
                        <TableCell>Facility Name</TableCell>
                        <TableCell>Appointment Time</TableCell>
                        <TableCell>Type</TableCell>
                        {/* <TableCell>Status</TableCell> */}
                        <TableCell></TableCell>
                        <TableCell className="text-center last-cell"></TableCell>
                    </TableRow>
                </TableHead>
                <br className="opacity-0" />
                {data.length > 0 ? (
                    <TableBody>
                        {data.map((row: PatientData) => (
                            <React.Fragment key={row.inmatename}>
                                <TableRow
                                    sx={{
                                        borderRadius: "20px",
                                        backgroundColor: "#FFFFFF",
                                        borderWidth: "0",
                                    }}
                                >
                                    <TableCell className="first-cell">
                                        <span className="font-semibold">
                                            {row.inmatename}
                                        </span>
                                        <div className="text-sm mt-1 underline quick-view-value cursor-pointer">
                                            <span className="-ml-1 mr-2 text-blue-500">
                                                <EventAvailableIcon fontSize="small" />
                                            </span>
                                            <a
                                                onClick={() => handleExpandRow(row.appointmentid)}
                                                aria-label="expand row"
                                            >
                                                Quick View
                                            </a>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            <span className="quick-view-label">{row.facilityName}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            <span className="quick-view-label">
                                                <strong>{appointmentdatetime(row.appointmentdatetime).date} &nbsp;&nbsp;&nbsp;&nbsp;{appointmentdatetime(row.appointmentdatetime).time}</strong>
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            <span className="quick-view-label">
                                                {row.appointmentTypeName}
                                            </span>
                                        </div>
                                    </TableCell>
                                    {/* <TableCell>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <span className="quick-view-label">
                                                <SquareRoundedIcon style={{ color: "#ffa96c", height: "15px", width: "15px",marginRight: "4px" }} />
                                                <span style={{ verticalAlign: "middle" }}>Scheduled</span>
                                            </span>
                                        </div>
                                    </TableCell> */}
                                    <TableCell>
                                        <div>
                                            <Button variant="contained" style={{ background: '#54bdbe' }} onClick={() => handleOpen(row.createdby, row.appointmentid)} >
                                                <AttachFileIcon style={{ transform: 'rotate(222deg)' }} />&nbsp;&nbsp;Files
                                            </Button>
                                        </div>
                                    </TableCell>
                                    <TableCell className="last-cell">
                                        <div>
                                            <Button variant="contained" onClick={() => handleJoinMeeting(row.providerId)}
                                             disabled={!isMeetingButtonEnabled(row.appointmentdatetime, row.appointmenttypeid)}
                                            >
                                            Join Meeting
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        className="p-0 bottom-radius"
                                        style={{
                                            background: "#F6F7FB",
                                            border: "0px solid #E7E5E4",
                                        }}
                                        colSpan={7}
                                    >
                                        <Collapse
                                            style={{ padding: "15px" }}
                                            in={expandedRow === row.appointmentid}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <div className="grid grid-cols-4 gap-2 text-sm">
                                                {renderCollapseRows(row)}
                                            </div>
                                        </Collapse>

                                    </TableCell>
                                </TableRow>
                                <br />
                            </React.Fragment>
                        ))}
                    </TableBody>
                ) : (
                    <TableBody>
                        <NoRecordFoundComponent cellSpan={7} />
                    </TableBody>
                )}
            </Table>
            <FileUploadModel
                open={open}
                onClose={handleClose}
                handleFileUpload={handleFileUpload}
                appointmentId={appointmentId}
            />
        </React.Fragment >
    );
}

const formatDate = (date: Date | string): string => {
    const parsedDate = new Date(date);
    return parsedDate instanceof Date && !isNaN(parsedDate.getTime())
        ? `${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}/${parsedDate
            .getDate()
            .toString()
            .padStart(2, '0')}/${parsedDate.getFullYear()}`
        : 'Invalid Date';
}

export default ITPTable;

const renderCollapseRows = (rowData: PatientData) => {
    const collapseData = [
        { label: "Current Meditations:", value: rowData.medications },
        { label: "Additional information given by facility:", value: rowData.additionalinfo },
        { label: "Date of Birth:", value: formatDate(rowData.inmatedob) },
        { label: "Will be seen at:", value: rowData.inmatewillbeseenname },
    ];

    const collapseRows = collapseData.map((item, index) => (
        <div
            className={`row col-span-1 sm:col-span-1 xs:col-span-4"`}
            key={index}
        >
            <div className="grid grid-cols-5">
                <div className="col-span-6 quick-view-label">{item.label}</div>
                <div className="text-gray-500 col-span-6 xl-ml-6 quick-view-value">
                    {item.value}
                </div>
            </div>
        </div>
    ));

    return collapseRows;
}
