import { Avatar } from "@mui/material";
import { checkboxCheckedPrimaryIcon, checkboxDefaultOutlineIcon, radioCheckedPrimaryIcon, radioUncheckedDefaultIcon } from "../assets/images";


export const checkBoxCheckedIcon = (
    <Avatar variant="square" src={checkboxCheckedPrimaryIcon} />
);

export const checkboxDefaultIcon = (
    <Avatar variant="square" src={checkboxDefaultOutlineIcon} />
);

export const radioCheckedIcon = (
    <Avatar variant="square" src={radioCheckedPrimaryIcon} />
);

export const radioDefaultIcon = (
    <Avatar variant="square" src={radioUncheckedDefaultIcon} />
);