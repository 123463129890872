import React from "react";
import Cookies from "js-cookie";
import { getTokenExpirationTime, isTokenExpired } from "../services/auth-service";
import useStore from "./store";

const AuthContext = React.createContext({
  isLoggedIn: false,
  login: (accessToken: string) => { },
  logout: () => { },
});

export function AuthContextProvider({ children }: any) {
  let isUserLoggedIn = false;

  const pstore : any = useStore();
  // const tokenHandler = () => {
  //   return tokens.accessToken;
  // };

  const tokens: any = {
    accessToken: Cookies.get("access-token"),
  };

  if (tokens.accessToken) {
    if (isTokenExpired(tokens.accessToken)) {
      Cookies.remove("access-token");
      pstore.reset();
      isUserLoggedIn = false;
    } else {
      isUserLoggedIn = true;
    }
  } else {
    isUserLoggedIn = false;
  }
  const loginHandler = (accessToken: string) => {
    const tokenExpiryDate = new Date(
      getTokenExpirationTime(accessToken) * 1000
    );

    Cookies.set("access-token", accessToken, {
      expires: tokenExpiryDate,
    });

    tokens.accessToken = Cookies.get("access-token");
    isUserLoggedIn = true;
  };
  const logoutHandler = () => {
    tokens.accessToken = undefined;
    // Remove access token cookie
    Cookies.remove("access-token");
    pstore.reset();
    isUserLoggedIn = false;
  };

  const contextValue = {
    isLoggedIn: isUserLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    //token: tokenHandler
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
export default AuthContext;
