import Facilitydashboard from "../../components/DashBoard/FacilityDashboard";
import Layout from "../../components/Layout";

function Dashboard() {
    return (
        <>
            <Layout>
                <div className="bg-indigo-50 xl:px-40 lg:px-40 md:px-10 sm:px-10 xs:px-8 ">
                    <div className="py-10 w-full">
                        <Facilitydashboard />
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Dashboard;