import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NoRecordFoundComponent from "./NoRecords";
import { FileUploadServiceInternal } from "../../services/fileUpload.service";
import { toast } from 'react-toastify';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'; 
import _lodash from 'lodash'; 

interface FileUploadData {
    appointmentid: number,
    blobstoragepath: string,
    created: string,
    uploadedname: string,
    filename: string
}


interface FileUploadModelProps {
    open: boolean;
    onClose: () => void;
    handleFileUpload: (file: File) => void;
    appointmentId: number;
}

const FileUploadModel = ({
    open,
    onClose,
    handleFileUpload,
    appointmentId,
}: FileUploadModelProps) => {
    const [file, setFile] = useState<File | null>(null);
    const [tableData, setTableData] = useState<any[]>([]);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const handleUploadButtonClick = () => {
        if (file) {
            handleFileUpload(file);
        }
    };

    const handleSortOrder = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleDownloadFile = async (fileName: string) => {
        const response = await FileUploadServiceInternal.downloadFile(fileName);
        if(response?.data.isSuccess){
            const data = response?.data.data;
            const byteCharacters = atob(data.contentBase64);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = data.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else{
            toast.error(response?.data.Message)
        }
        return response;
    }

    const uploaddatetime = (date: any) => {
        const originalDate = new Date(date);        
        // Convert UTC time to local timezone
        const localDate = new Date(originalDate.getTime() - (originalDate.getTimezoneOffset() * 60000));
        
        const formattedDate = `${(localDate.getMonth() + 1).toString().padStart(2, '0')}/${localDate.getDate().toString().padStart(2, '0')}/${localDate.getFullYear()}`;
        const formattedTime = `${localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;        
        return {
            date: formattedDate,
            time: formattedTime
        };
    }

    const fetchData = async () => {
        try {
        const response = await FileUploadServiceInternal.getAllUploadedFile(appointmentId);
            if (response?.data.isSuccess) {
                let sortedData = _lodash.orderBy(response?.data.data, 'created', sortOrder); // Use lodash orderBy to sort
                setTableData(sortedData);
            } else {
                toast.error("No data found.");
            }
        } 
        catch (error) {
            toast.error("Failed to fetch data. Please try again later.");
        }
    }

    useEffect(() => {
        fetchData();
    }, [sortOrder]); 

    useEffect(() => {
        setFile(null);
        if (open) {
            fetchData(); // Fetch data only when the modal is open
        }
    }, [open]);

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle></DialogTitle>
            <DialogActions>
                <label htmlFor="model-upload">
                    <input type="file" onChange={(e) => setFile(e.target.files?.[0] || null)} />
                </label>
                <Button variant="contained" color="primary" component="span" onClick={handleUploadButtonClick}>
                    Upload
                </Button>
                <Button onClick={onClose} color="primary" variant="outlined">
                    Close
                </Button>
            </DialogActions>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow
                            style={{
                                color: "#A2A8BA",
                                backgroundColor: "#F6F7FB",
                            }}
                        >
                            <TableCell className="first-cell">File Name</TableCell>
                            <TableCell>
                                Upload Date Time
                                <Button onClick={handleSortOrder}>
                                    {sortOrder === 'desc' ? <ArrowUpward /> : <ArrowDownward />}
                                </Button>
                            </TableCell>
                            <TableCell>Upload By</TableCell>                            
                            <TableCell className="text-center last-cell">Action</TableCell>                          
                        </TableRow>
                    </TableHead>
                    <br className="opacity-0" />
                    {tableData.length > 0 ? (
                        <TableBody>
                            {tableData.map((row: FileUploadData) => (
                                <React.Fragment key={0}>
                                    <TableRow
                                        sx={{
                                            borderRadius: "20px",
                                            backgroundColor: "#FFFFFF",
                                            borderWidth: "0",
                                        }}
                                    >
                                        <TableCell>
                                            <div>
                                                <span className="quick-view-label">{row.filename}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <span className="quick-view-label">{uploaddatetime(row.created).date}&nbsp;{uploaddatetime(row.created).time}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <span className="quick-view-label">{row.uploadedname}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell className="text-center">
                                            <Button variant="contained" onClick={() => handleDownloadFile(row.filename)}>
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>

                                </React.Fragment>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <NoRecordFoundComponent cellSpan={7} />
                        </TableBody>
                    )}
                </Table>
            </DialogContent>
        </Dialog>
    );
};

export default FileUploadModel;
