import React, { useContext } from "react";
import Login from "../../pages/Login";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ResetPassword from "../ForgotPassword/ResetPassword";
import AuthContext from "../../store/auth-context";
import Dashboard from "../../pages/Dashboard";
import NewAppointMent from "../../pages/Appointment";
import Provider from "../../pages/Dashboard/provider";
import ProviderManagent from "../../pages/ProviderManagement/providermanagement";
import NewProvider from "../../pages/ProviderManagement/newprovider";
import userStore from "../../store/store";
import CorrectionalFacilityManagement from "../../pages/CorrectionalFacilityManagement/CorrectionalFacilityManagement";
import NewCorrectionalFacility from "../../pages/CorrectionalFacilityManagement/NewCorrectionalFacility";
import ITPUserManagement from "../../pages/ITPUserManagement/ITPUserManagement";
import NewITPUser from "../../pages/ITPUserManagement/NewITPUser";
import ITPUser from "../../pages/Dashboard/ITPUser";
import SSOLogin from "../../pages/SSOLogin";
import CorrectionalFacilityUserManagement from "../../pages/CorrectionalFacilityUserManagement/CorrectionalFacilityUserManagement";
import NewCorrectionalFacilityUser from "../../pages/CorrectionalFacilityUserManagement/NewCorrectionalFacilityUser";
import PatientFiles from "../../pages/PatientFileTemplates/PatientFiles";
import FacilityFileTemplates from "../../pages/PatientFileTemplates/FacilityFileTemplates";
import RequestTypeManagement from "../../pages/RequestTypeManagement/RequestTypeManagement";
import NewRequestType from "../../pages/RequestTypeManagement/NewRequestType";

const AppRoutes: React.FC = () => {
  // const pstore: any = userStore();
  // const {email, id_role} = pstore.getUser();
  let id_role = 0;
  const authCtx: any = useContext(AuthContext);
  if (authCtx.isLoggedIn) {
    const pstore: any = userStore();
    const userstore = pstore.getUser();
    if (userstore) {
      id_role = userstore.id_role;
    }
  }
  const location = useLocation();

  const routesList = [
    {
      path: "/",
      //component: authCtx.isLoggedIn ? (id_role == 1 ? Provider : Dashboard) : Login,
      component: Login,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/login",
      component: Login,
      exact: true,
      isProtectedRoute: !authCtx.isLoggedIn,
    },
    {
      path: "/itp-login",
      component: SSOLogin,
      exact: true,
      isProtectedRoute: !authCtx.isLoggedIn,
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
      exact: true,
      isProtectedRoute: !authCtx.isLoggedIn,
    },
    {
      path: "/reset-password/:token",
      component: ResetPassword,
      exact: true,
      isProtectedRoute: !authCtx.isLoggedIn,
    },
    {
      path: "/facility-dashboard",
      component: Dashboard,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/provider-dashboard",
      component: Provider,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/itp-dashboard",
      component: ITPUser,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/new-appointment",
      component: NewAppointMent,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/provider-management",
      component: ProviderManagent,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/new-provider",
      component: NewProvider,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/edit-provider",
      component: NewProvider,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/correctional-facility-management",
      component: CorrectionalFacilityManagement,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/new-correctional-facility",
      component: NewCorrectionalFacility,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/edit-correctional-facility",
      component: NewCorrectionalFacility,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/ITP-user-management",
      component: ITPUserManagement,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/new-ITP-user",
      component: NewITPUser,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/edit-ITP-user",
      component: NewITPUser,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/correctional-facility-user-management",
      component: CorrectionalFacilityUserManagement,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/new-correctional-facility-user",
      component: NewCorrectionalFacilityUser,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/edit-correctional-facility-user",
      component: NewCorrectionalFacilityUser,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/patient-file-templates",
      component: PatientFiles,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/patient-files",
      component: FacilityFileTemplates,
      exact: true,
      //isProtectedRoute: authCtx.isLoggedIn
    },
    {
      path: "/request-type-management",
      component: RequestTypeManagement,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
    {
      path: "/new-request-type",
      component: NewRequestType,
      exact: true,
      isProtectedRoute: authCtx.isLoggedIn,
    },
  ];

  return (
    <Routes>
      {routesList.map((routeItem, index) => {
        let element;
        if (authCtx.isLoggedIn) {
          if (
            ["/login", "/forgot-password", "/itp-login"].includes(
              location.pathname.toLowerCase()
            ) ||
            location.pathname.toLowerCase().startsWith("/reset-password")
          ) {
            if (id_role === 1) {
              element = <Navigate to="/provider-dashboard" replace />;
            } else if (id_role === 2 || 4) {
              element = <Navigate to="/facility-dashboard" replace />;
            } else if (id_role === 3) {
              element = <Navigate to="/itp-dashboard" replace />;
            }
          } else {
            element = <routeItem.component />;
          }
        } else {
          element = <routeItem.component />;
        }

        return <Route key={index} path={routeItem.path} element={element} />;
      })}
    </Routes>
  );
};

export default AppRoutes;
