import { Link } from "react-router-dom";
import React from "react";
// import JailLogo from "../../assets/images/jailtele-logo.png"
import JailLogo from "../../assets/images/Jail-TeleHealthLogo.png"

export default function LoginHeader() {
  return (
    <div className="mb-8">
      <div className="flex justify-center">
        <img alt="" className="h-14 w-full" style={{width:"400px", height:"80px"}} src={JailLogo}/>
      </div>
      <p className="mt-2 text-center text-sm text-gray-600 mt-5">
      </p>
    </div>
  );
}