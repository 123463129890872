import { CircularProgress, Container, Backdrop } from "@mui/material";

const FullPageLoader = () => {
  return (
    <Backdrop open={true} style={{ zIndex: 9999, color: "rgba(0, 0, 0, 0.5)" }}>
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    </Backdrop>
  );
};

export default FullPageLoader;
